const APPJSH = {
  ROLES: {
    SCHOOL: 'school',
    PTA: 'PTA',
    CLUB: 'club',
  },

  EMAIL: 'mail',
  EVENT_TICKET: 'ticket',
  // 暗号化の種類
  EMAIL_CRYPTS: {
    SSL: 'ssl',
    TLS: 'tls',
  },
  EMAIL_TEMPLATE_TYPES: {
    TEXT: 'text',
    HTML: 'html',
  },

  // 配信日時設定
  EMAIL_SEND_TYPES: {
    NOW: 'now',         // 今すぐ配信
    RESERVE: 'reserve', // 予約配信
  },

  // 添付ファイルの追加
  // just allow upload image png, jpeg, jpg, pdf, txt, zip, rar
  EMAIL_ATTACHMENT_TYPES: {
    IMAGE: 'image',
    PDF: 'pdf',
    TEXT: 'text',
    ZIP: 'zip',
    CSV: 'csv',
  },

  EMAIL_MAX_ATTACHMENT_SIZE: 10, // MB
  EMAIL_MAX_ATTACHMENT_COUNT: 3,
  FILE_EXTENSIONS: ['png', 'jpeg', 'jpg', 'pdf', 'txt', 'zip', 'rar'],

  EMAIL_STATUS: {
    pending: '配信中',
    success: '配信済',
    error: 'エラー',
  },

  // クラブ一覧
  CLUB_TYPE_ONCE: 'once',
  CLUB_TYPE_DAILY: 'daily',
  CLUB_TYPE_WEEKLY: 'weekly',
  CLUB_TYPE_MONTHLY: 'monthly',
  CLUB_REPEAT_TYPES: {
    once: '一度',
    daily: '毎日',
    weekly: '毎週',
    monthly: '毎月',
  },
  CLUB_STATUS: {
    active: '有効',
    inactive: '無効',
  },
  WEEKDAYS: {
    0: '日', // 0 is Sunday
    1: '月', // 1 is Monday
    2: '火', // 2 is Tuesday
    3: '水', // 3 is Wednesday
    4: '木', // 4 is Thursday
    5: '金', // 5 is Friday
    6: '土', // 6 is Saturday
  },

  CLUB_APPROVED_TYPES: {
    approved: '承認済',
    pending: '未承認',
    rejected: '却下',
  },
}

export default APPJSH
