<script setup>
import { ref } from 'vue'
import Editor from '@tinymce/tinymce-vue'

const key = import.meta.env.VITE_APP_TINYMCE_API_KEY
const editor = ref(null)

const props = defineProps({
  class: {
    type: String,
    default: 'flex h-full w-full',
  },
})

const content = defineModel('content', {
  type: String,
  default: '',
})
</script>
<template>
  <div :class="props.class">
    <main class="flex flex-col h-full w-full">
      <Editor
        ref="editor"
        :api-key="key"
        :init="{
          toolbar_mode: 'wrap',
          language: 'ja',
          menubar: false,
          plugins: 'anchor autolink codesample image link lists media searchreplace table visualblocks wordcount fullscreen',
          toolbar: 'blocks fontfamily fontsize | bold italic underline | link image media table | align numlist bullist | removeformat fullscreen',
          placeholder: 'ここに入力してください',
          fullscreen_native: true,
          statusbar: false,
        }"
        v-model="content" 
      />
    </main>
  </div>
</template>
