<script setup>
import { ref, getCurrentInstance } from 'vue'
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import TablePagination from './TablePagination.vue';
import ClubItemModal from './ClubItemModal.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()

const clubItemModal = ref(false)

const doSearch = async () => {
  $root.overlay.loading = true
  await clubStore.getItems()
  $root.overlay.loading = false
}
</script>
<template>
  <div class="w-full bg-white rounded-md flex flex-col gap-2">
    <div class="flex items-end justify-between">
      <!-- searching -->
      <div class="flex items-end gap-2">
        <!-- searching by text -->
        <div>
          <input 
            type="text" 
            class="px-2 py-1 border border-gray-200 rounded w-56" 
            v-model="clubStore.filter.text" 
            placeholder="クラブ名で検索"
            @keyup.enter="doSearch"
          />
        </div>

        <!-- button search -->
        <button 
          class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out" 
          @click.prevent="doSearch"
        >
          <MagnifyingGlassIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
          検索
        </button>
      </div>
      <div class="flex items-center gap-1">
        <!-- add new btn -->
        <button 
          class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out" 
          @click.prevent="clubItemModal.open()"
        >
          <PlusIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
          新規追加
        </button>
      </div>
    </div>
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th v-for="header in clubStore.headers" class="font-normal p-2 text-left">{{ header.text }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="clubStore.items && clubStore.items.length">
          <tr v-for="item in clubStore.items" class="border-b border-b-gray-200 hover:bg-gray-50">
            <td 
              class="p-2 font-bold text-pblue cursor-pointer hover:opacity-80 hover:underline" 
              @click.prevent="clubItemModal.open(item)"
            >
              {{ item.name }}
            </td>
            <td class="p-2">
              {{ item.teacher }}<br />
              <span class="text-xs text-grey">{{ item.teacher_phone }}</span>
            </td>
            <td class="p-2">{{ item.total_childs ?? 0 }}/{{ item.members ?? 0 }}</td>
            <td class="p-2">{{ item.members - item.total_childs }}/{{ item.members ?? 0 }}</td>
            <td class="p-2">{{ item.total_relateds ?? 0 }}/{{ item.members ?? 0 }}</td>
            <td class="p-2 text-center">
              <button 
                class="px-2 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out" 
                @click.prevent="clubItemModal.open(item)"
              >
                編集
              </button>
            </td>
          </tr>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-xs text-grey" :colspan="clubStore.headers.length">
            データがありません
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination 
      :total="clubStore.total" 
      :perPage="clubStore.perpage"
      v-model:currentPage="clubStore.page"
      :onPageChange="doSearch"
    />
  </div>
  <ClubItemModal ref="clubItemModal" />
</template>
