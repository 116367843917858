<script setup>
import { ref, getCurrentInstance } from 'vue'
import { 
  TabGroup, TabList, Tab, TabPanels, TabPanel,
  TransitionRoot, TransitionChild, Dialog, DialogPanel,
} from '@headlessui/vue'
import {PlusIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useDatabaseStore } from '../../js/store/database'
import { useStaff } from '../../js/store/staff'
import { useRoute} from 'vue-router'

const $route = useRoute()
const databaseStore = useDatabaseStore()
const useStaffStore = useStaff()
const $root = getCurrentInstance()?.appContext.config.globalProperties
const isOpen = ref(false)
const parent = ref({})
const flagEdit = ref(0)
const hasFather = ref(false)
const hasMother = ref(false)

const tab = ref(0)
const changeTab = (index) => {
  tab.value = index;
}

const open = async (item) => {
  tab.value = 0
  isOpen.value = true
  parent.value = JSON.parse(JSON.stringify(item));
  if(item.id) {
    flagEdit.value = 1;
  } else {
    flagEdit.value = 0;
    parent.value.status = 1;
    parent.value.address = databaseStore.item.address;
    parent.value.students = [databaseStore.item];
  }

  hasFather.value = false;
  hasMother.value = false;
  if(databaseStore.item.families.find((fml) => fml.relationship == 'Father') || databaseStore.item.staff.find((stf) => stf.gender == '男性')) {
    hasFather.value = true;
  }
  if(databaseStore.item.families.find((fml) => fml.relationship == 'Mother') || databaseStore.item.staff.find((stf) => stf.gender == '女性')) {
    hasMother.value = true;
  }  
}

const close = () => {
  isOpen.value = false
}

const update = async() => {
  $root.overlay.loading = true
  if (tab.value == 0) {
    try {
      const response = await axios.post('/api/update-family', parent.value);
      if(response.status == 200) {
        $root.push.success(response.data.message)
        databaseStore.getItem($route.params.id);
        close();
      } else {
        $root.push.error(response.data.message)
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
    $root.overlay.loading = false
    return
  }

  // TODO for save data with tab = 1
  // ...
  $root.overlay.loading = false
}

const searching = ref({})
const staff = ref({})
const searchingFlag = ref(false)

const searchStaff = async() => {
  searchingFlag.value = true;
  if(searching.value.phone) {
    staff.value = await useStaffStore.searchStaff(searching.value.phone);
    staff.value.map((stf) => {
      if(databaseStore.item.staff.find((i) => i.id == stf.id)){
        stf.added = true;
      }else {
        stf.added = false;
      }
    })
  }
  searchingFlag.value = false;
}

const addStaff =  async(staff) => {
  if(staff.gender == '男性' && databaseStore.item.families.find((fml) => fml.relationship == 'Father') || (staff.gender == '男性' && databaseStore.item.staff.find((stf) => stf.gender == '男性'))) {
    $root.push.error('父が登録したため、再度父の登録はできません。');
    return;
  }
  if(staff.gender == '女性' && databaseStore.item.families.find((fml) => fml.relationship == 'Mother') || (staff.gender == '女性' && databaseStore.item.staff.find((stf) => stf.gender == '女性'))) {
    $root.push.error('母が登録したため、再度母の登録はできません。');
    return;
  }
  $root.overlay.loading = true
  try {
    const response = await axios.post('/api/add-parent-staff', {student_id: $route.params.id, staff_id: staff.id});
    if(response.status == 200) {
      $root.push.success(response.data.message)
      databaseStore.getItem($route.params.id);
      searchStaff();
    } else {
      $root.push.error(response.data.message)
    }
  } catch (error) {
    console.error('Error adding user:', error);
  }
    $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all p-4 border-t-2 border-t-secondary"
            >
              <TabGroup @change="changeTab">
                <!-- add parent normal, add parent who is school staff -- 保護者 -->
                <TabList class="flex items-center gap-1 border-b border-primary px-2 transition-all delay-75 duration-300 ease-in-out">
                  <Tab 
                    class="border-t border-l border-r rounded-t-lg px-4 py-2 transition-all delay-75 duration-300 ease-in-out bg-white"
                    :class="{
                      'border-primary -mb-0.5': tab == 0,
                    }"
                  >保護者</Tab>
                  <Tab v-if="!flagEdit"
                    class="border-t border-l border-r rounded-t-lg px-4 py-2 transition-all delay-75 duration-300 ease-in-out bg-white"
                    :class="{
                      'border-primary -mb-0.5': tab == 1,
                    }"
                  >保護者（学校職員）</Tab>
                </TabList>                
                <TabPanels class="py-2">
                  <TabPanel>
                    <div class="flex flex-col gap-2">
                      <div class="flex w-full gap-2">
                        <div class="flex-auto">
                          <label for="name" class="text-sm text-gray-500">氏名（漢字）<span class="text-red font-bold">(*)</span></label>
                          <input v-model="parent.kanji_name" type="text" class="w-full border border-gray-300 rounded p-2" />
                        </div>
                        <div class="flex-auto">
                          <label for="name" class="text-sm text-gray-500">氏名（ローマ字）<span class="text-red font-bold">(*)</span></label>
                          <input v-model="parent.romaji_name" type="text" class="w-full border border-gray-300 rounded p-2" />
                        </div>
                      </div>
                      <div class="flex w-full gap-2">
                        <div class="flex-auto">
                          <label for="name" class="text-sm text-gray-500">状態</label>
                          <select v-model="parent.status" class="w-full border border-gray-300 rounded p-2">
                              <option value="0">無効</option>
                              <option value="1">有効</option>
                            </select>
                        </div>
                        <div class="flex-auto">
                          <label for="name" class="text-sm text-gray-500">続柄<span class="text-red font-bold">(*)</span></label>
                          <select v-model="parent.relationship" class="w-full border border-gray-300 rounded p-2">
                              <option value="Father" v-if="!hasFather">父</option>
                              <option value="Mother" v-if="!hasMother">母</option>
                              <option value="Preschool">未就学児</option>
                              <option value="Other">他</option>
                            </select>
                        </div>
                      </div>

                      <div class="grid grid-cols-2 gap-2">
                        <div class="flex flex-col">
                          <label for="name" class="text-sm text-gray-500">携帯番号<span class="text-red font-bold">(*)</span></label>
                          <input v-model="parent.phone" type="text"  class="w-full border border-gray-300 rounded p-2" />
                        </div>

                        <div class="flex flex-col">
                          <label for="name" class="text-sm text-gray-500">メールアドレス</label>
                          <input v-model="parent.email" type="text"  class="w-full border border-gray-300 rounded p-2" />
                        </div>
                      </div>

                      <div class="flex flex-col">
                        <label for="name" class="text-sm text-gray-500">現住所</label>
                        <input v-model="parent.address" type="text"  class="w-full border border-gray-300 rounded p-2" />
                      </div>
                    </div>
                    <!-- cancel + save button -->
                    <div class="flex justify-center gap-2 mt-6 font-semibold">
                      <button class="px-4 py-2 border rounded" type="button" @click.prevent="close">キャンセル</button>
                      <button class="px-4 py-2 text-white bg-primary rounded" @click.prevent="update()">{{flagEdit? '更新' : '登録する'}}</button>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div class="flex flex-col gap-2">
                      <div class="flex items-center gap-1">
                        <div class="w-56">
                          <input v-model="searching.phone" class="w-full border border-gray-300 rounded p-2" placeholder="携帯番号" />
                        </div>
                        <div class="relative">
                          <button @click="searchStaff" 
                            class="rounded bg-primary px-4 py-2 text-white font-semibold hover:opacity-80"
                            :class="{
                              'bg-gray-300 cursor-not-allowed': searchingFlag,
                            }"
                            :disabled="searchingFlag"
                          >
                            <span v-if="!searchingFlag">検索</span>
                            <svg v-else class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div v-if="staff.length" class="flex w-full flex-col">
                        <table class="table w-full">
                          <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
                            <tr>
                              <th class="p-1">名前</th>
                              <th class="p-1">性別</th>
                              <th class="p-1">電話番号</th>
                              <th class="p-1">位置</th>
                              <th class="p-1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="stf in staff">
                              <tr
                                class="bg-gray-100"
                              >
                              
                                <td>
                                  <span>{{ stf.kanji_name }} <br> {{ stf.romaji_name }}</span>
                                </td>
                                <td>{{ stf.gender }}</td>
                                <td>{{ stf.phone }}</td>
                                <td>{{stf.staff_position.name }}</td>
                                <td class="text-right pr-1">
                                    <button v-if="!stf.added"
                                      type="button"
                                      class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-orange-500 text-white"
                                      @click.prevent="addStaff(stf)"
                                    >
                                      <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                                    </button>
                                    <button v-else
                                      type="button"
                                      class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                                    >
                                      <CheckIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                                    </button>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>                        
                      </div>
                      <div v-else>
                        <div class="text-center text-gray-400">データがありません</div>
                      </div>
                    </div>
                    <!-- cancel + save button -->
                    <div class="flex justify-center gap-2 mt-6 font-semibold">
                      <button class="px-4 py-2 border rounded" type="button" @click.prevent="close">終了</button>
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
