<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline';
import imgQrcode from '../../../../public/qrcode.png'
const isOpen = ref(false)

const close = () => {
  isOpen.value = false

  // make tag: main > .tox.tox-tinymce sticky css property
  const main = document.querySelector('main')
  const tox = document.querySelector('.tox.tox-tinymce')
  if (main && tox) {
    tox.style.position = ''
  }
}
const open = () => {
  isOpen.value = true

  // make tag: main > .tox.tox-tinymce sticky css property
  const main = document.querySelector('main')
  const tox = document.querySelector('.tox.tox-tinymce')
  if (main && tox) {
    tox.style.position = 'sticky'
  }
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="close()" class="z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25 jsh-template" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary tw-modal">
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between px-6 py-2 bg-gray-100"
              >
                ヘルプ

                <button @click="close" class="text-gray-500 hover:text-gray-700">
                  <XMarkIcon class="w-6 h-6" />
                </button>
              </DialogTitle>
              <div class="grid grid-cols-2 gap-4 px-6 py-4">
                <div class="rounded-lg h-full bg-yellow flex flex-col">
                  <div class="w-full bg-gray-200 font-semibold p-2">
                    テンプレート
                  </div>
                  <div class="p-2">
                    <p>こんにちは、</p>
                    <p></p><br>
                    <p>私たちの学校のイベントは1週間後です！友達を招待して一緒に参加するのを忘れないでください。</p>
                    <p></p><br>
                    <p>こちらがQRコードのリストです：</p>
                    <p>[#FRIENDS]</p>
                    <p>[#FRIEND-NAME]: [#FRIEND-QRCODE]</p>
                    <p>[#END-FRIENDS]</p>
                    <p></p><br>
                    <p>それでは、会場でお会いしましょう！</p>
                    <p></p><br>
                    <p>敬具、</p>
                    <p>私たちの学校チーム</p>
                  </div>
                </div>
                <div class="rounded-lg h-full bg-gray-100">
                  <div class="w-full bg-gray-200 font-semibold p-2">
                    メールの内容
                  </div>
                  <div class="p-2">
                    <p>こんにちは、</p>
                    <p></p><br>
                    <p>私たちの学校のイベントは1週間後です！友達を招待して一緒に参加するのを忘れないでください。</p>
                    <p></p><br>
                    <p>こちらがQRコードのリストです：</p>
                    <p>雪華(ゆきか): <img :src="imgQrcode" alt="QRコード" class="w-16 h-16" loading="lazy" /></p>
                    <p>美咲(みさき): <img :src="imgQrcode" alt="QRコード" class="w-16 h-16" loading="lazy" /></p>
                    <p>美咲(みさき): <img :src="imgQrcode" alt="QRコード" class="w-16 h-16" loading="lazy" /></p>
                    <p></p><br>
                    <p>それでは、会場でお会いしましょう！</p>
                    <p></p><br>
                    <p>敬具、</p>
                    <p>私たちの学校チーム</p>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
