<script setup>
import { ref, onMounted } from 'vue';
import { PencilIcon } from '@heroicons/vue/24/outline';
import ModalSchedule from './ModalSchedule.vue';
import { useResource } from '../../../js/store/resource';

const modalSchedule = ref(null);
const resourceStore = useResource()
onMounted(() => {
  resourceStore.getTimeItems();
})
const openModalSchedule = () => {
  modalSchedule.value.open(JSON.parse(JSON.stringify(resourceStore.itemsTime)));
}
</script>
<template>
  <table class="w-full table border-b divide-y">
    <thead class="border-t-2 border-t-secondary bg-gray-100">
      <tr>
        <th class="text-left p-1 font-normal">
          対象日
        </th>
        <th class="text-center p-1 font-normal">
          基本利用時間
        </th>
        <th class="text-center p-1 font-normal">
          操作
        </th>
      </tr>
    </thead>
    <tbody class="divide-y">
      <tr v-for="time in resourceStore.itemsTime">
        <td class="p-1">
          {{ time.name_day }}
        </td>
        <td class="p-1 text-center">
          {{ time.opening_time.slice(0, -3) }} ～ {{ time.closing_time.slice(0, -3) }}
        </td>
        <td class="p-1 text-center">
          <button class="p-1.5 rounded-full bg-green" @click.prevent="openModalSchedule">
            <PencilIcon class="w-4 h-4 text-white" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ModalSchedule ref="modalSchedule" />
</template>
