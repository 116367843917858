<script setup>
import { ref, getCurrentInstance, } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { useEventTicket } from 'store/event-ticket';
const eventTicketStore = useEventTicket()
import dayjs from 'dayjs'

import DateTimeHelper from 'helper/DatetimeHelper';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })

const save = async () => {
  $root.overlay.loading = true
  await eventTicketStore.validateGroup()
  if (Object.entries(eventTicketStore.error).length > 0) {
    $root.push.error('必須項目を入力してください')
    $root.overlay.loading = false
    return
  }
  eventTicketStore.saveGroups().then((result) => {
    if (result.length > 0) {
      $root.push.success('保存しました。')
      eventTicketStore.groups = result
    }
    else {
      $root.push.error('エラーが発生しました')
    }
    close()
  })
    .catch((err) => {
      close()
      $root.push.error('エラーが発生しました')
    });

  $root.overlay.loading = false

}

const format = (date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}
const handleStartTime = (val) => {
  eventTicketStore.group.start_time = val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
}
const handleEndTime = (val) => {
  eventTicketStore.group.end_time = val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
}
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary p-4">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 text-center">グループ新規登録</DialogTitle>
              <div class="mt-2 flex flex-col gap-2">

                <div class="mt-2 flex flex-col gap-2 max-h-[500px] overflow-auto">
                  <div class="flex w-full gap-2">
                    <div class="w-4/12">
                      グループ名<span class="text-error">*</span>
                    </div>
                    <div class="w-8/12">
                      入校理由
                    </div>
                  </div>
                  <div class="flex w-full gap-2">
                    <div class="w-4/12">
                      <input v-model="eventTicketStore.group.name" type="text" maxlength="100"
                        :class="eventTicketStore.error.name ? 'border-rose-500' : 'border-gray-300'"
                        class="w-full border rounded p-2 " />
                    </div>
                    <div class="w-8/12">
                      <input v-model="eventTicketStore.group.reason" type="text" maxlength="255"
                        class="w-full border rounded p-2 border-gray-300" />
                    </div>
                  </div>
                  <div class="flex w-full gap-2">
                    <div class="w-4/12">
                      <label class="text-sm text-gray-500">状態</label>
                      <select v-model="eventTicketStore.group.status" class="p-2 border border-gray-300 rounded w-full">
                        <option value="1">有効</option>
                        <option value="0">無効</option>
                      </select>
                    </div>
                    <div class="w-4/12">
                      <label>開始日<span class="text-error">*</span></label>
                      <VueDatePicker v-model="eventTicketStore.group.start_time" time-picker-inline :format="format"
                        locale="ja" :teleport="true" cancelText="キャンセル" selectText="選択" placeholder="----/--/-- --:--"
                        @update:model-value="handleStartTime"
                        :class="eventTicketStore.error.start_time ? 'border-error' : ''">
                      </VueDatePicker>
                    </div>
                    <div class="w-4/12">
                      <label>終了日</label>
                      <VueDatePicker v-model="eventTicketStore.group.end_time" time-picker-inline :format="format"
                        locale="ja" :teleport="true" cancelText="キャンセル" selectText="選択" placeholder="----/--/-- --:--"
                        @update:model-value="handleEndTime"
                        :class="eventTicketStore.error.start_time ? 'border-error' : ''">
                      </VueDatePicker>
                    </div>
                  </div>
                </div>

                <div class="flex items-center gap-4 justify-center pt-4">
                  <button type="button" class="p-2 rounded-md bg-primary text-white hover:opacity-80 min-w-20"
                    @click="save">
                    保存</button>
                  <button type="button" class="p-2 rounded-md bg-gray-100 text-gray-800 hover:opacity-80"
                    @click="close">
                    キャンセル
                  </button>
                </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
