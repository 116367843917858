<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useClubRequestStore } from '../../../js/store/club-request';
import ItemModal from './ItemModal.vue';
import Calendar from './Calendar.vue';
import CalendarFilter from './CalendarFilter.vue';

const itemModal = ref(null)

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubRequestStore = useClubRequestStore()

onMounted(async () => {
  clubRequestStore.getResources()
})

const editItem = () => {
  itemModal.value.open(clubRequestStore.item.id)
}
const getItems = async () => {
  $root.overlay.loading = true
  await clubRequestStore.getItems()
  $root.overlay.loading = false
}

const showFilter = () => {
  clubRequestStore.showFilter = !clubRequestStore.showFilter

  if (clubRequestStore.showFilter) {
    // for checked showing
    if (!clubRequestStore.filter.rooms.length) {
      clubRequestStore.filter.rooms = clubRequestStore.rooms.map(room => room.id)
    }
    if (!clubRequestStore.filter.resources.length) {
      clubRequestStore.filter.resources = clubRequestStore.resources.map(resource => resource.id)
    }
  } else {
    // remove rooms and resources if same as all
    if (clubRequestStore.filter.rooms.length === clubRequestStore.rooms.length) {
      clubRequestStore.filter.rooms = []
    }
    if (clubRequestStore.filter.resources.length === Object.values(clubRequestStore.resources).length) {
      clubRequestStore.filter.resources = []
    }
  }
}
</script>
<template>
  <div class="flex flex-col gap-2 w-full p-4">
    <div 
      class="flex w-full h-full overflow-hidden transition-all duration-300 delay-75 ease-in-out gap-2"
    >
      <div
        class="flex-none transition-all duration-300 delay-75 ease-in-out max-h-full overflow-hidden"
        :class="{
          'w-0': !clubRequestStore.showFilter,
          'w-48': clubRequestStore.showFilter,
        }"
      >
        <CalendarFilter />
      </div>
      <div class="grow">
        <div 
          class="flex flex-col gap-2 w-full h-full overflow-y-auto relative transition-all duration-300 delay-75 ease-in-out"
          
        >
          <Calendar @add="itemModal.open()" @edit="editItem" @filter="showFilter" />
        </div>
      </div>
    </div>
  </div>
  <ItemModal ref="itemModal" @saved="getItems()" />
</template>
