<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { ClockIcon } from '@heroicons/vue/24/outline';
import { useResource } from '../../../js/store/resource';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const resourceStore = useResource()
const $root = getCurrentInstance()?.appContext.config.globalProperties

const isOpen = ref(false)
const itemsUpdate = ref([])
const open = async (items) => {
  itemsUpdate.value = items
  itemsUpdate.value.map((item) => {
    const [hoursOpen, minutesOpen, secondsOpen] = item.opening_time.split(':').map(Number);
    item.opening_time = { "hours": hoursOpen, "minutes": minutesOpen, "seconds": secondsOpen };
    const [hoursClose, minutesClose, secondsClose] = item.closing_time.split(':').map(Number);
    item.closing_time = { "hours": hoursClose, "minutes": minutesClose, "seconds": secondsClose };
  })
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
const save = async() => {
  $root.overlay.loading = true
  itemsUpdate.value.map((item) => {
    item.opening_time = `${item.opening_time.hours}:${item.opening_time.minutes}:${item.opening_time.seconds}`;
    item.closing_time = `${item.closing_time.hours}:${item.closing_time.minutes}:${item.closing_time.seconds}`;
  })
  try {
    const response = await axios.post('/api/update_resources-time', itemsUpdate.value);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      resourceStore.getTimeItems();
      close();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-sm transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary"
            >
              <div class="bg-gray-100 font-semibold text-primary py-2 px-4">
                基本利用時間設定
              </div>
              <div class="flex flex-col gap-2 p-4">
                <table class="table border-b">
                  <thead class="bg-gray-100">
                    <tr>
                      <th class="font-normal text-left p-1">対象日</th>
                      <th class="font-normal text-left p-1">基本利用時間設定</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y">
                    <tr v-for="time in itemsUpdate">
                      <td class="p-1">{{ time.name_day }}</td>
                      <td class="p-1">
                        <div class="flex items-center gap-1">
                          <div>
                            <div class="relative bg-gray-100 rounded w-20">
                              <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none" />
                              <VueDatePicker 
                                v-model="time.opening_time"
                                locale="ja"
                                auto-apply 
                                time-picker
                                format="HH:mm"
                                class="mydatepicker w-fit"
                                :clearable="false"
                                input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                              >
                                <template #dp-input="{ value }">
                                  <input type="text" :value="value" class="bg-transparent w-full pr-8 pl-2 py-1 cursor-pointer" />
                                </template>
                              </VueDatePicker>
                              
                            </div>
                          </div>
                          ~
                          <div>
                            <div class="relative bg-gray-100 rounded w-20">
                              <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none" />
                              <VueDatePicker 
                                v-model="time.closing_time" 
                                auto-apply 
                                time-picker
                                format="HH:mm"
                                class="mydatepicker w-fit"
                                :clearable="false"
                                input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                              >
                                <template #dp-input="{ value }">
                                  <input type="text" :value="value" class="bg-transparent w-full pr-8 pl-2 py-1 cursor-pointer" />
                                </template>
                              </VueDatePicker>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="p-1">土日祝休</td>
                      <td class="p-1">
                        <div class="flex items-center gap-1">
                          <div>
                            <div class="relative bg-gray-100 rounded w-20">
                              <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none" />
                              <VueDatePicker 
                                v-model="date" 
                                auto-apply 
                                time-picker
                                format="HH:mm"
                                locale="ja"
                                class="mydatepicker w-fit"
                                :clearable="false"
                                input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                              >
                                <template #dp-input="{ value }">
                                  <input type="text" :value="value" class="bg-transparent w-full pr-8 pl-2 py-1 cursor-pointer" />
                                </template>
                              </VueDatePicker>
                            </div>
                          </div>
                          ~
                          <div>
                            <div class="relative bg-gray-100 rounded w-20">
                              <ClockIcon class="absolute w-4 h-4 top-1/2 right-2 transform -translate-y-1/2 cursor-none pointer-events-none" />
                              <VueDatePicker 
                                v-model="date" 
                                auto-apply 
                                time-picker
                                format="HH:mm"
                                locale="ja"
                                class="mydatepicker w-fit"
                                :clearable="false"
                                input-class-name="w-full pr-8 pl-2 py-1 cursor-pointer"
                              >
                                <template #dp-input="{ value }">
                                  <input type="text" :value="value" class="bg-transparent w-full pr-8 pl-2 py-1 cursor-pointer" />
                                </template>
                              </VueDatePicker>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
                <div class="flex items-center gap-2 w-full mt-4 font-semibold">
                  <div class="w-1/2">
                    <button class="px-4 py-1 bg-primary text-white rounded-md w-full" @click.prevent="save">保存</button>
                  </div>
                  <div class="w-1/2">
                    <button class="px-4 py-1 border border-gray-400 bg-gray-100 rounded-md w-full" @click.prevent="close">キャンセル</button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
