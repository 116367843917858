<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRoute} from 'vue-router'
import { UserIcon, UserGroupIcon, BookmarkIcon, PencilIcon, PlusIcon, TrashIcon, ArrowUturnLeftIcon } from '@heroicons/vue/24/outline'
import { useDatabaseStore } from '../../../js/store/database'
import DatabaseDetailStudentModal from '../../components/DatabaseDetailStudentModal.vue';
import DatabaseDetailFamilyModal from '../../components/DatabaseDetailFamilyModal.vue';
import QrItemModal from '../../components/QrItemModal.vue';
import ConfirmModal from '../../components/ConfirmModal.vue';
import TextHelper from '../../../js/helper/TextHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const $route = useRoute()
const databaseStore = useDatabaseStore()
const databaseDetailStudentModal = ref(null)
const databaseDetailFamilyModal = ref(null)
const refQrModal = ref(null)
const confirmModal = ref(null)
const deleteItem = ref({student_id: $route.params.id})

onMounted(() => {
  databaseStore.getItem($route.params.id);
})

const deleteRelationship =  async() => {
  $root.overlay.loading = true
  try {
      const response = await axios.post('/api/delete-relationship', deleteItem.value);
      if(response.status == 200) {
        $root.push.success(response.data.message)
        databaseStore.getItem($route.params.id);
      } else {
        $root.push.error(response.data.message)
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
    $root.overlay.loading = false
    return
}

</script>
<template>
  <div class="flex flex-col gap-4 p-4 w-full">
    <div class="w-full">
      <button
      @click="router.push({ path: `/database` })"
        type="button" 
        class="flex flex-row p-1 items-center group justify-center rounded rounded-mb bg-background hover:opacity-80 min-w-20 border text-sblue gap-1"
      >
      <ArrowUturnLeftIcon class="w-4 h-4 transition duration-300 delay-75 ease-in-out group-hover:scale-110" />
      戻る
      </button>
    </div>
    <div class="flex items-center border border-t-2 border-t-secondary p-4 w-1/4 gap-4">
      <!-- qrcode -->
      <div class="flex-none w-32">
        <div class="w-full h-32 p-1 group border rounded">
          <img v-if="databaseStore.item.qr_code_url"
            @click.prevent="refQrModal.open(databaseStore.item.qr_code_url)"
            class="object-contain group-hover:scale-110 transition-transform duration-300 delay-75 ease-in-out"
            :src="databaseStore.item.qr_code_url" 
            alt="QR Code" 
            loading="lazy" 
          />
        </div>
      </div>
      <div class="grow">
        <div class="flex flex-col divide-y divide-dashed">
          <div class="flex items-center gap-2">
            <h2 class="text-3xl font-bold text-primary py-4">
              {{ databaseStore.item.kanji_name }}
            </h2>
            <PencilIcon 
              class="w-5 h-5 text-primary cursor-pointer" 
              @click.prevent="databaseDetailStudentModal.open(databaseStore.item)"
            />
          </div>
          
          <div class="w-full grid grid-cols-2 gap-4 py-4 divide-x divide-dashed">
            <div class="flex flex-col text-gray-400 px-2">
              クラス
              <div class="font-semibold text-charcoal">{{ databaseStore.item.school_level}} {{ databaseStore.item.grade }} {{ databaseStore.item.class_name }}</div>
            </div>
            <div class="flex flex-col text-gray-400 px-6">
              状態
              <div class="font-semibold text-charcoal">{{ databaseStore.item.status ? '在学' : '退校' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 w-full">
      <div class="flex flex-col border border-t-2 border-t-secondary divide-y">
        <div class="flex items-center justify-between gap-2 px-2 py-1 bg-gray-200 font-semibold">
          <div class="flex items-center gap-2">
            <UserIcon class="w-5 h-5" />
            <h4>個人情報</h4>
          </div>
          <PencilIcon 
            class="w-5 h-5 text-primary cursor-pointer" 
            @click.prevent="databaseDetailStudentModal.open(databaseStore.item)"
          />
        </div>
        <div class="flex flex-col divide-y divide-dashed p-2">
          <div class="flex items-center justify-between p-2">
            <div class="flex flex-col text-gray-400">
              生徒ID
              <div class="font-semibold text-charcoal">{{ databaseStore.item.student_code }}</div>
            </div>
          </div>
          <div class="flex items-center justify-between p-2">
            <div class="flex flex-col text-gray-400">
              性別
              <div class="font-semibold text-charcoal">{{ databaseStore.item.gender }}</div>
            </div>
          </div>
          <div class="flex flex-col p-2 text-gray-400">
            生年月日
            <div class="font-semibold text-charcoal">
              {{databaseStore.brdString}}
            </div>
          </div>
          <div class="flex flex-col p-2 text-gray-400">
            現住所
            <div class="font-semibold text-charcoal">
              {{databaseStore.item.address}}
            </div>
          </div>
          <div class="flex flex-col p-2 text-gray-400">
            メモ
            <div class="font-semibold text-charcoal">
              {{ databaseStore.item.note }}
            </div>
          </div>
        </div>
      </div>
      
      <div class="flex flex-col border border-t-2 border-t-secondary divide-y">
        <div class="flex items-center justify-between gap-2 px-2 py-1 bg-gray-200 font-semibold">
          <div class="flex items-center gap-2">
            <UserGroupIcon class="w-5 h-5" />
            <h4>保護者情報</h4>
          </div>
          <div class="flex items-center gap-2">
            <button class="flex items-center gap-1 px-2 py-px text-violet-600" @click.prevent="databaseDetailFamilyModal.open({students: databaseStore.item})">
              <PlusIcon 
                class="w-5 h-5 cursor-pointer" 
              />保護者
            </button>
            <button class="flex items-center gap-1 px-2 py-px text-violet-600" @click.prevent="databaseDetailStudentModal.open({families: databaseStore.item.families, address: databaseStore.item.address})">
              <PlusIcon 
                class="w-5 h-5 cursor-pointer" 
              />通う兄弟
            </button>
          </div>
        </div>
        <div class="flex flex-col divide-y divide-dashed p-2">
          <div v-for="staff in databaseStore.item.staff" class="flex flex-col text-gray-400 p-2">
            <div class="flex items-center justify-between text-">
              {{ staff.gender == '男性' ? '父' : staff.gender == '女性' ? '母' : '不確定' }}（学校職員-{{staff.staff_position.name }}）
              <div class="flex items-center gap-2">
                <TrashIcon 
                  class="w-4 h-4 text-red cursor-pointer" 
                  @click.prevent="confirmModal.open();deleteItem.staff_id=staff.id"
                />
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex-none w-12">
                <div class="w-full h-12 p-px group border rounded">
                  <img v-if="staff.qr_code_url"
                    class="object-contain group-hover:scale-110 transition-transform duration-300 delay-75 ease-in-out"
                    :src="staff.qr_code_url"  
                    alt="QR Code" 
                    loading="lazy"
                    @click.prevent="refQrModal.open(staff.qr_code_url)" 
                  />
                </div>
              </div>
              <div class="grow">
                <div class="flex flex-col font-semibold text-charcoal">
                  <div class="flex items-center justify-between">
                    {{ staff.kanji_name }}
                    <div>{{ staff.phone }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    {{ staff.romaji_name }}
                    <div>{{ staff.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="child in databaseStore.item.families" class="flex flex-col text-gray-400 p-2">
            <div class="flex items-center justify-between">
              {{ TextHelper.getTextRelationship(child.relationship) }}
              <div class="flex items-center gap-2">
                <PencilIcon 
                  class="w-4 h-4 text-primary cursor-pointer" 
                  @click.prevent="databaseDetailFamilyModal.open(child)"
                />
                <TrashIcon 
                  class="w-4 h-4 text-red cursor-pointer" 
                  @click.prevent="confirmModal.open();deleteItem.family_id=child.id"
                />
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex-none w-12">
                <div class="w-full h-12 p-px group border rounded">
                  <img v-if="child.qr_code_url"
                    class="object-contain group-hover:scale-110 transition-transform duration-300 delay-75 ease-in-out"
                    :src="child.qr_code_url"  
                    alt="QR Code" 
                    loading="lazy"
                    @click.prevent="refQrModal.open(child.qr_code_url)" 
                  />
                </div>
              </div>
              <div class="grow">
                <div class="flex flex-col font-semibold text-charcoal">
                  <div class="flex items-center justify-between">
                    {{ child.kanji_name }}
                    <div>{{ child.phone }}</div>
                  </div>
                  <div class="flex items-center justify-between">
                    {{ child.romaji_name }}
                    <div>{{ child.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="databaseStore.subStudent.length">
            <div v-for="std in databaseStore.subStudent"  class="flex flex-col text-gray-400 p-2">
              <div class="flex items-center justify-between">
                通う兄弟
                <div class="flex items-center gap-2">
                  <PencilIcon 
                    class="w-4 h-4 text-primary cursor-pointer" 
                    @click.prevent="databaseDetailStudentModal.open(std)"
                  />
                  <!-- <TrashIcon class="w-4 h-4 text-error cursor-pointer" /> -->
                </div>
              </div>
              <div class="flex items-center gap-2">
                <div class="flex-none w-12">
                  <div class="w-full h-12 p-px group border rounded">
                    <img v-if="std.qr_code_url"
                      class="object-contain group-hover:scale-110 transition-transform duration-300 delay-75 ease-in-out"
                      :src="std.qr_code_url" 
                      alt="QR Code" 
                      loading="lazy"
                      @click.prevent="refQrModal.open(std.qr_code_url)" 
                    />
                  </div>
                </div>
                <div class="grow">
                  <div class="flex flex-col font-semibold text-charcoal">
                    <div class="flex items-center justify-between">
                      {{ std.kanji_name }}
                      <div>{{ std.gender }} / {{ databaseStore.item.status ? '在学' : '退校' }}</div>
                    </div>
                    <div class="flex items-center justify-between">
                      {{ std.romaji_name }}
                      <div>{{ std.school_level }} {{ std.grade }} {{ std.class_name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <DatabaseDetailStudentModal ref="databaseDetailStudentModal" />
  <DatabaseDetailFamilyModal ref="databaseDetailFamilyModal" />
  <QrItemModal ref="refQrModal" />
  <ConfirmModal 
    ref="confirmModal"
    title="紐づけを解除"
    message="この生徒に紐づけを解除しますか？"
    :confirm="deleteRelationship"
  />
</template>
