import { defineStore } from 'pinia'
import DateTimeHelper from '../helper/DatetimeHelper';
import FormatHelper from '../helper/FormatHelper';
// import APPJSH from '../helper/ConstantHelper';
import TextHelper from '../helper/TextHelper';

export const useClubStore = defineStore('club', {
  state: () => ({
    headers: [ // japanese
      {
        text: 'クラブ名',
        field: 'name',
      },
      {
        text: '責任者氏名',
        field: 'teacher',
      },
      // total kid/total members
      {
        text: '子供数/メンバー数',
        field: 'children',
      },
      // total adult/total members
      {
        text: '大人数/メンバー数',
        field: 'adults',
      },
      // total related to school
      {
        text: '学校関連/メンバー数',
        field: 'related_to_school',
      },
    ],
    filter: {
      status: '',
      text: '',
    },
    items: [],
    page: 1,
    total: 0,
    perpage: 16,
    totalChildren: 0,
    totalRelatedToSchool: 0,
    
    searched: false,
    searchMemberText: '',
    searchText: '',
    autoGenerateQrcode: true,
    members: [],
    totalMembers: 0,
    currentPageMembers: 1,
    perpageMembers: 10,
    member: {
      id: 0,
      name: '',
      email: '',
      phone: '',
      dob: '',
      gender: 'male',
      note: '',
      expired_at: '',
      related_to_school: false,
    },

    item: {
      id: 0,
      name: '',
      teacher: '',
      teacher_phone: '',
      note: '',
      status: true,
      manager: 0,
      members: [],
      total_childs: 0,
      total_relateds: 0,
    },
    modalTitle: '追加', // add or edit : japanese : 追加 or 編集
    modalSubtitle: 'クラブ情報を編集します', // japanese : クラブ

    managers: [],

    isSaved: false,

    pageSchedule: 1,
    perPageSchedule: 10,
  }),
  getters: {
    totalPageSchedule() {
      return Math.ceil(this.totalSchedules / this.perPageSchedule)
    },
    showingFromSchedule() {
      return this.totalSchedules ? (this.pageSchedule - 1) * this.perPageSchedule + 1 : 0
    },
    showingToSchedule() {
      const v = this.pageSchedule * this.perPageSchedule > this.totalSchedules 
        ? this.totalSchedules 
        : this.pageSchedule * this.perPageSchedule

      if (v <= this.perPageSchedule && this.totalSchedules < this.perPageSchedule) {
        return this.totalSchedules
      }

      return v
    },
  },
  actions: {
    resetItem() {
      this.item = {
        id: 0,
        name: '',
        teacher: '',
        teacher_phone: '',
        note: '',
        status: true,
        manager: 0,
        members: [],
        total_childs: 0,
        total_relateds: 0,
      }
    },
    async getItem(id) {
      if (!id) {
        return
      }

      const response = await axios.get(`/api/club/${id}`)

      if (response.status === 200) {
        this.item = Object.assign(this.item, response.data.data)

        if (this.item.members && this.item.members.length) {
          this.item.members = this.item.members.map(member => {
            if (member.expired_at) {
              member.expired_at = DateTimeHelper.format(member.expired_at, 'YYYY-MM-DD')
            }
            return member
          })
        }

        if (!this.item.manager) {
          this.item.manager = 0
        } else {
          this.item.manager = parseInt(this.item.manager)
        }

        if (response.data.total) {
          this.totalMembers = response.data.total
        }
      }
    },
    async getItemSchedules(clubId) {
      if (!clubId) {
        return
      }

      const response = await axios.get(`/api/club/${clubId}/schedules?page=${this.pageSchedule}`)

      if (response.status === 200) {
        this.item.schedules = response.data.data
        this.totalSchedules = response.data.total

        this.item.schedules.map(schedule => {
          if (schedule.start_at) {
            schedule.start_at = DateTimeHelper.format(schedule.start_at, 'YYYY/MM/DD(ddd)　HH:mm')
          }
          if (schedule.end_at) {
            schedule.end_at = DateTimeHelper.format(schedule.end_at, 'YYYY/MM/DD(ddd)　HH:mm')
          }
          if (schedule.updated_at) {
            schedule.updated_at = DateTimeHelper.format(schedule.updated_at, 'YYYY/MM/DD(ddd)　HH:mm')
          }

          if (schedule.resources) {
            schedule.resources = schedule.resources.map((r) => {
              let arr = []
              if (r.room) {
                arr.push(r.room)
              }
              if (r.resource) {
                arr.push(`${r.resource} x ${r.quantity}`)
              }

              return arr
            }).join(', ')
          }
        })
      }
    },
    async getItems() {
      if (this.page > Math.ceil(this.total / this.perPage)) {
        this.page = Math.ceil(this.total / this.perPage)
      }

      if (this.page < 1) {
        this.page = 1
      }

      try {
        // get email history
        const response = await axios.get('/api/club', {
          params: {
            page: this.page,
            perpage: this.perpage,
            status: this.filter.status,
            text: this.filter.text,
          }
        })

        if (response.status === 200 && response.data.status == 'success') {
          this.items = response.data.data
          this.total = response.data.total
        }
      } catch (error) {
        this.items = []
        this.total = 0

        console.log('エラーが発生しました')
      }
    },
    async saveItem() {
      const payload = Object.assign({}, this.item)

      this.isSaved = false
      try {
        const response = await axios.post('/api/club', payload)
        if (response.status === 200 && response.data.status == 'success') {
          // add new item to list
          if (this.item.id === 0) {
            this.items.unshift(response.data.data)
            this.total += 1
          } else {
            // update item in list
            const index = this.items.findIndex(item => item.id === this.item.id)
            if (index !== -1) {
              this.items[index] = response.data.data
            }
          }

          this.item = {
            id: 0,
            name: '',
            teacher: '',
            teacher_phone: '',
            note: '',
            status: true,
            manager: 0,
            members: [],
            total_childs: 0,
            total_relateds: 0,
          }
        }

        return response.data.message
      } catch (error) {
        return 'エラーが発生しました'
      }
    },

    getManagers() {
      axios.get('/api/club-managers')
        .then(response => {
          if (response.status === 200 && response.data.status == 'success') {
            this.managers = response.data.data
          }
        })
    },

    toogleScheduleWeekly(dayNumber) {
      if (!this.item.schedules) {
        this.item.schedules = {}
      }

      if (!this.item.schedules[dayNumber]) {
        this.item.schedules[dayNumber] = {
          day: dayNumber,
          time_start: '',
          time_end: '',
          room: '',
        }
      } else {
        delete this.item.schedules[dayNumber]
      }

      // re-update time_start, time_end, room follow the first schedule
      this.syncSchedules()
    },

    syncSchedules() {
      if (this.item.is_same_time) {
        const minKey = this.firstKeySchedules
        for (const key in this.item.schedules) {
          if (key !== minKey && minKey !== -1) {
            this.item.schedules[key].time_start = this.item.schedules[minKey].time_start
            this.item.schedules[key].time_end = this.item.schedules[minKey].time_end
          }
        }
      }

      if (this.item.is_same_room) {
        const minKey = this.firstKeySchedules
        for (const key in this.item.schedules) {
          if (key !== minKey && minKey !== -1) {
            this.item.schedules[key].room = this.item.schedules[minKey].room
          }
        }
      }
    },

    async searchMembers() {
      if (!this.searchText) {
        return
      }

      this.searched = true
      const response = await axios.get('/api/club-members', {
        params: {
          text: this.searchText,
        }
      })

      if (response.status === 200 && response.data.status == 'success') {
        this.members = response.data.data
      }

      return response.data.message
    },
    async addMember() {
      const response = await axios.post('/api/club-member', {
        id: 0,
        club_id: this.item.id,
        ...this.member,
        dob: this.member.dob ? FormatHelper.formatDate(this.member.dob) : '',
        auto: this.autoGenerateQrcode,
      })

      if (response.status === 200 && response.data.status == 'success') {
        this.getMembers()
      }
    },
    async addGuest() {
      const response = await axios.post('/api/club-guest', {
        id: 0,
        club_id: this.item.id,
        ...this.member,
        dob: this.member.dob ? FormatHelper.formatDate(this.member.dob) : '',
        auto: this.autoGenerateQrcode,
        expired_at: this.member.expired_at ? DateTimeHelper.format(this.member.expired_at, 'YYYY-MM-DD HH:mm:ss') : null,
      })

      if (response.status === 200 && response.data.status == 'success') {
        this.getMembers()

        // reset member
        this.member = {
          id: 0,
          name: '',
          email: '',
          phone: '',
          dob: '',
          gender: 'male',
          note: '',
          expired_at: '',
        }
      }

      return response.data
    },
    async addMemberExisting(member) {
      const response = await axios.post('/api/club-member', {
        club_id: this.item.id,
        ...member,
        auto: this.autoGenerateQrcode,
      })

      if (response.status === 200 && response.data.status == 'success') {
        this.getMembers()
      }

      return response.data
    },
    async removeMember(id) {
      if (this.item.id === 0) {
        return
      }

      const response = await axios.delete(`/api/club-member/${this.item.id}/${id}`)

      if (response.status === 200 && response.data.status == 'success') {
        this.getMembers()
      }
    },
    genQrcodes() {
      return axios.post('/api/club-qrcodes', {
        club_id: this.item.id,
      })
    },
    async getMembers() {
      const text = TextHelper.encodeText(this.searchMemberText.trim())

      await axios.get(`/api/club/${this.item.id}/members?page=${this.currentPageMembers}&perpage=${this.perpageMembers}&text=${text}`)
        .then(response => {
          if (response.status === 200 && response.data.status == 'success') {
            this.item.members = response.data.data
            if (this.currentPageMembers === 1) {
              this.totalMembers = response.data.total
            }

            this.item.total_childs = response.data.total_childs || 0
            this.item.total_relateds = response.data.total_relateds || 0
          }
        })
    }
  },
});
