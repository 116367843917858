import { defineStore } from 'pinia'

export const useHoliday = defineStore('holiday', {
  state: () => ({
    item: {},
    items: [],
    year: new Date().getFullYear(),
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/holidays?year=` + this.year);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    },
    async save() {

    }
  },
  getters: {
    searchItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
})
