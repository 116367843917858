<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { ChevronRightIcon, MagnifyingGlassIcon, PlusIcon, QueueListIcon, PhoneIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import ClubItemMemberAddGuest from './ClubItemMemberAddGuest.vue';
import TextHelper from '../../js/helper/TextHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const clubItemMemberAddGuest = ref(null)
const isSearching = ref(false)

const isOpen = ref(false)
const open = () => {
  clubStore.searchText = ''
  clubStore.members = []
  clubStore.searched = false

  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const searchHandler = async () => {
  isSearching.value = true
  try {
    await clubStore.searchMembers()
  } catch (error) {
    console.error('Error searching:', error);
  }
  isSearching.value = false
}

const addGuest = async () => {
  close()

  clubItemMemberAddGuest.value.open()
}

const addMember = async (member) => {
  $root.overlay.loading = true
  try {
    const response = await clubStore.addMemberExisting(member)
    if (response.status === 'error') {
      $root.push.error(response.message)
    } else {
      $root.push.success('メンバーを追加しました')
    }
  } catch (error) {
    $root.push.error('メンバーの追加に失敗しました')
  }
  $root.overlay.loading = false
}

const getNameText = (mem) => {
  if (mem.name) {
    return mem.name
  }

  if (mem.kanji_name) {
    return mem.kanji_name + (mem.romaji_name ? '<br>' + mem.romaji_name : '')
  }

  if (mem.romaji_name) {
    return mem.romaji_name
  }

  return ''
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all rounded-xl p-4"
            >
              <!-- add member to club #A -->
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 mb-4"
              >
                <div class="px-2 py-1 rounded-md bg-gray-100 text-primary">{{ clubStore.item.name }}</div>
                <ChevronRightIcon class="w-4 h-4 text-gray-400" />
                メンバー追加
              </DialogTitle>
              <div class="flex flex-col gap-2">
                <!-- search by phone or student id only -->
                <div class="flex items-center gap-1">
                  <div class="grow relative">
                    <MagnifyingGlassIcon class="w-5 h-5 text-gray-400 absolute top-1/2 left-2 transform -translate-y-1/2" />
                    <input 
                      v-model="clubStore.searchText"
                      type="text" 
                      class="w-full pl-8 pr-12 py-2 border border-gray-200 rounded" 
                      placeholder="電話番号または学籍番号で検索"
                      :disabled="isSearching"
                      @keyup.enter="searchHandler"
                    />
                    <div class="rounded px-1 py-px bg-stone-100 absolute right-2 top-2 text-gray-400 text-xxs">Enter</div>
                  </div>
                  <div class="flex-none w-fit">
                    <!-- button search -->
                    <button 
                      type="button"
                      class="px-4 py-2 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                      :disabled="isSearching"
                      @click.prevent="searchHandler"
                    >
                      <svg v-show="isSearching" class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      検索
                    </button>
                  </div>
                </div>

                <!-- option auto generate qrcode after add member to club -->
                <div v-if="clubStore.members && clubStore.members.length" class="flex items-center gap-1 cursor-pointer">
                  <input 
                    type="checkbox" 
                    class="form-checkbox rounded text-primary w-5 h-5" 
                    id="auto-generate-qrcode"
                    v-model="clubStore.autoGenerateQrcode"
                  />
                  <label for="auto-generate-qrcode">メンバー追加後、QRコードを自動生成する</label>
                </div>

                <table v-if="clubStore.members && clubStore.members.length" class="table w-full border-y py-1 border-t-2 border-t-secondary">
                  <tbody class="divide-y">
                    <template v-for="mem in clubStore.members">
                      <tr>
                        <td class="text-center w-4">
                          <ChevronRightIcon class="w-4 h-4 text-gray-400 rotate-90" />
                        </td>
                        <td class="w-8 text-center relative">
                          <div class="w-8 flex justify-center">
                            <QueueListIcon class="w-4 h-4 text-gray-400" />
                          </div>
                          <div v-if="mem.relateds && mem.relateds.length" class="w-px bg-gray-300 absolute left-1/2 bottom-0 h-3"></div>
                        </td>
                        <td>
                          <span v-html="mem.name"></span>
                        </td>
                        <td>
                          <div v-if="mem.type === 'guest'">
                            ゲスト
                          </div>
                          <div v-else-if="mem.type === 'student'" >
                            学生
                          </div>
                          <div v-else>
                            保護者
                          </div>
                        </td>
                        <td>
                          <div v-if="mem.type === 'student'" class="text-primary bg-gray-100 rounded-lg px-2 py-0.5 w-fit flex items-center gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="fill-primary w-5" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M192-132q-26 0-43-17t-17-43v-376q0-26 17-43t43-17h220v-172q0-12 8-20t20-8h80q12 0 20 8t8 20v172h220q26 0 43 17t17 43v376q0 26-17 43t-43 17H192Zm0-28h576q14 0 23-9t9-23v-376q0-14-9-23t-23-9H548v40q0 11-8.5 19.5T520-532h-80q-11 0-19.5-8.5T412-560v-40H192q-14 0-23 9t-9 23v376q0 14 9 23t23 9Zm64-110h208v-2q0-13-7-23.5T438-312q-19-8-38.5-12t-39.5-4q-20 0-39.5 4T282-312q-12 6-19 16.5t-7 23.5v2Zm304-62h160v-28H560v28Zm-200-28q18 0 31-13t13-31q0-18-13-31t-31-13q-18 0-31 13t-13 31q0 18 13 31t31 13Zm200-60h160v-28H560v28ZM440-560h80v-240h-80v240Zm40 180Z"/></svg>
                            <a title="生徒ID" href="#">{{ mem.student_code }}</a>
                          </div>
                          <div v-else class="flex items-center gap-1 px-2 py-0.5 rounded-lg bg-stone-100 w-fit">
                            <PhoneIcon class="w-3 text-gray-400" />
                            <a title="電話番号" :href="`tel:${mem.phone}`">{{ mem.phone }}</a>
                          </div>
                        </td>
                        <td class="text-right">
                          <button 
                            type="button"
                            class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                            title="クラブに追加"
                            @click.prevent="addMember(mem)"
                          >
                            <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                          </button>
                        </td>
                      </tr>
                      <template v-if="mem.relateds && mem.relateds.length">
                        <tr v-for="(people, idx) in mem.relateds">
                          <td></td>
                          <td class="relative">
                            <div class="w-1/2 h-1/2 border-l border-b border-gray-300 absolute right-0 top-0"></div>
                            <div v-if="idx < mem.relateds.length - 1" class="w-1/2 h-1/2 border-l border-gray-300 absolute right-0 bottom-0"></div>
                          </td>
                          <td>
                            <div class="flex items-center">
                              <div class="flex items-center justify-center w-6">
                                <QueueListIcon class="w-4 h-4 text-gray-400" />
                              </div>
                              <span v-html="people.name"></span>
                            </div>
                          </td>
                          <td>
                            <div v-if="people.type === 'student'">
                              学生
                            </div>
                            <div v-else>
                              {{ TextHelper.getTextRelationship(people.relationship) }}
                            </div>
                          </td>
                          <td>
                            <div v-if="people.type === 'student'" class="text-primary bg-gray-100 rounded-lg px-2 py-0.5 w-fit flex items-center gap-1">
                              <svg xmlns="http://www.w3.org/2000/svg" class="fill-primary w-5" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M192-132q-26 0-43-17t-17-43v-376q0-26 17-43t43-17h220v-172q0-12 8-20t20-8h80q12 0 20 8t8 20v172h220q26 0 43 17t17 43v376q0 26-17 43t-43 17H192Zm0-28h576q14 0 23-9t9-23v-376q0-14-9-23t-23-9H548v40q0 11-8.5 19.5T520-532h-80q-11 0-19.5-8.5T412-560v-40H192q-14 0-23 9t-9 23v376q0 14 9 23t23 9Zm64-110h208v-2q0-13-7-23.5T438-312q-19-8-38.5-12t-39.5-4q-20 0-39.5 4T282-312q-12 6-19 16.5t-7 23.5v2Zm304-62h160v-28H560v28Zm-200-28q18 0 31-13t13-31q0-18-13-31t-31-13q-18 0-31 13t-13 31q0 18 13 31t31 13Zm200-60h160v-28H560v28ZM440-560h80v-240h-80v240Zm40 180Z"/></svg>
                              <a title="生徒ID" href="#">{{ people.student_code }}</a>
                            </div>
                            <div v-else class="flex items-center gap-1 px-2 py-0.5 rounded-lg bg-stone-100 w-fit">
                              <PhoneIcon class="w-3 text-gray-400" />
                              <a title="電話番号" :href="`tel:${people.phone}`">{{ people.phone }}</a>
                            </div>
                          </td>
                          <td class="text-right">
                            <button 
                              type="button"
                              class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                              title="クラブに追加"
                              @click.prevent="addMember(people)"
                            >
                              <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                            </button>     
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
                <div v-else>
                  <div v-if="clubStore.searched" class="text-center text-gray-400">検索結果がありません</div>
                </div>
                <div class="text-xs text-gray-500 mt-2">
                    ※ メンバー追加ボタンをクリックすると、メンバーがクラブに追加されます
                  </div>
                <div class="flex justify-center mt-6 gap-4">
                  <!-- cancel -->
                  <button 
                    class="px-4 py-2 bg-white border rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                    @click.prevent="close"
                  >
                    キャンセル
                  </button>

                  <!-- add guest -->
                  <button 
                    class="px-4 py-2 bg-tertiary rounded hover:opacity-80 group flex items-center gap-1 transition-all duration-300 delay-75 ease-in-out"
                    @click.prevent="addGuest"
                  >
                    <PlusIcon class="w-5 h-5 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
                    ゲスト追加
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <ClubItemMemberAddGuest ref="clubItemMemberAddGuest" />
</template>
