<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/vue/24/outline';

import { useEventTicket } from 'store/event-ticket';
const eventTicketStore = useEventTicket()

import EventTicketModel from './EventTicketModel.vue';

import QrItemModal from 'views/components/QrItemModal.vue';
const refQrModal = ref(null)

import ConfirmModal from 'views/components/ConfirmModal.vue';
const confirmDeleteModal = ref(null)

import TablePagination from 'views/components/TablePagination.vue';
import SendEmailModal from './SendEmailModal.vue';

const sendEmailModal = ref(null)

onMounted(async () => {
    fetchData()
    eventTicketStore.fetchGroups()
})

const fetchData = async () => {
    $root.overlay.loading = true
    await eventTicketStore.getItems()
    $root.overlay.loading = false
}
const refEventTicketModel = ref(null)
const showModel = (item) => {
    if (item.id > 0) {
        eventTicketStore.item = JSON.parse(JSON.stringify(item))
        eventTicketStore.item.groups = eventTicketStore.item.groups.map(group => group.id)
    }
    else {
        eventTicketStore.item = JSON.parse(JSON.stringify(eventTicketStore.intItem))
    }
    refEventTicketModel.value.open()
}

import ImportModal from './ImportModal.vue';
const refImportModal = ref(null)

const doSearch = () => {
    // selected.value = [];
    fetchData()
}

const deleteItem = ref(0)
const deleteEventTicket = async () => {
    $root.overlay.loading = true
    try {
        const response = await axios.delete(`/api/event-ticket/${deleteItem.value}`)
        if (response.status == 200) {
            $root.push.success('正常に削除されました')
            fetchData()
        }
        else {
            $root.push.error('エラーが発生しました')
        }

        $root.overlay.loading = false
    } catch (error) {
        $root.push.error('エラーが発生しました')
        $root.overlay.loading = false
    }
}

const sendEmail = () => {
    sendEmailModal.value.open()
}
const regenQRCode = async () => {
    $root.overlay.loading = true
    eventTicketStore.regenQRCode().then((result) => {
        if (result.code == 200) {
            $root.push.success('QR コードが正常に生成されました。')
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }
        else {
            $root.push.error('エラーが発生しました')
        }
        $root.overlay.loading = false
    })
        .catch((err) => {
            $root.overlay.loading = false
            $root.push.error('エラーが発生しました')
        });

}
// const selected = ref([])
// const checkAll = (checked) => {
//     selected.value = [];
//     //add all checked
//     if (checked == true) {
//         if (eventTicketStore.items.length > 0) {
//             selected.value = [];
//             eventTicketStore.items.forEach((item) => {
//                 selected.value.push(item.id);
//             });
//         }
//     }
// }
// const checkItem = (_id, checked) => {
//     if (checked == true) {
//         selected.value.push(_id);
//     }
//     else {
//         selected.value = selected.value.filter((i) => i !== _id)
//     }
// }
</script>
<template>
    <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">
        <div class="flex justify-between">
            <button class="px-4 h-8 bg-primary shadow text-white rounded hover:opacity-80"
                @click.prevent="showModel({})">
                新規登録
            </button>
            <div class="flex gap-2">
                <button class="px-4 h-8 bg-sky-500 shadow text-white rounded hover:opacity-80"
                    @click.prevent="refImportModal.open()">
                    インポート
                </button>
                <button class="px-4 h-8 bg-orange-700 shadow text-white rounded hover:opacity-80">
                    <a href="sample/sample_event_ticket_import.xlsx"
                        download="sample_event_ticket_import.xlsx">サンプルファイル</a>
                </button>
            </div>
        </div>
        <hr>
        <div class="flex justify-between">
            <div class="flex gap-2">
                <input type="search" class="px-4 h-8 border border-gray-200 rounded w-56 focus:border-none"
                    v-model="eventTicketStore.filter.text" @keydown.enter="doSearch()" placeholder="電話番号、名前等で検索" />

                <select v-model="eventTicketStore.filter.group" class="px-4 h-8 border border-gray-300 rounded"
                    @change="doSearch()">
                    <option value="">-- グループ --</option>
                    <template v-for="group in eventTicketStore.groups">
                        <option :value="group.id">{{ group.name }}</option>
                    </template>
                </select>

                <select v-model="eventTicketStore.filter.send" class="px-4 h-8 border border-gray-300 rounded"
                    @change="doSearch()">
                    <option value="">-- メール送信状態 --</option>
                    <template v-for="status in eventTicketStore.emailStatus">
                        <option :value="status.value">{{ status.text }}</option>
                    </template>
                </select>

                <button
                    class="px-4 h-8 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                    @click.prevent="doSearch()">
                    <MagnifyingGlassIcon
                        class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />検索
                </button>
            </div>
            <div class="flex gap-2">
                <template v-if="eventTicketStore.items.length > 0 && $root.$route.query.regen">
                    <button class="px-0 py-1.5 bg-sky-500 shadow text-white rounded hover:opacity-80 min-w-40"
                        @click.prevent="regenQRCode()">
                        QRコード 更新
                    </button>
                </template>
                <button class="px-4 h-8 bg-sky-500 shadow text-white rounded hover:opacity-80"
                    @click.prevent="sendEmail()">
                    メール送信
                </button>
            </div>
        </div>

        <table class="w-full table">
            <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
                <tr>
                    <!-- <th>
                        <div class="flex gap-2 items-center justify-center">
                            <input type="checkbox" class="h-4 w-4" @click="checkAll($event.target.checked)">
                        </div>
                    </th> -->
                    <th v-for="header in eventTicketStore.headers" class="font-normal p-2"
                        :class="header.field == 'count' ? 'text-center' : 'text-left'"
                        :style="`width:${header.width};`">
                        {{ header.text }}
                    </th>
                    <th class="font-normal p-2">操作</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="eventTicketStore.items.length">
                    <tr v-for="(item, index) in eventTicketStore.items" class="border-b border-b-gray-200">
                        <!-- <td class="p-2">
                            <div class="flex gap-2 items-center justify-center">
                                <input type="checkbox" class="h-4 w-4" :checked="selected.includes(item.id)"
                                    :value="item.id" @click="checkItem(item.id, $event.target.checked)">
                            </div>
                        </td> -->
                        <td
                            class="p-2 text-blue-500 font-semibold hover:underline hover:opacity-80 hover:cursor-pointer">
                            <div @click.prevent="showModel(item)">
                                {{ item.kanji_name }} <br> {{ item.romaji_name }}
                            </div>
                        </td>
                        <td class="p-2">
                            {{ item.groups.map((group) => group.name).join(', ') }}
                        </td>
                        <td class="p-2">{{ item.gender }}</td>
                        <td class="p-2">{{ item.email }}</td>
                        <td class="p-2">{{ item.phone }}</td>
                        <td class="p-2">{{ item.date_of_birth }}</td>
                        <td class="p-2">{{ item.address }}</td>
                        <td class="p-2">
                            {{ item.status == 1 ? '有効' : '無効' }}
                        </td>
                        <td class="p-2">
                            <span class="px-2 py-0.5 rounded-full border text-xs" :class="{
                    'bg-green/30 border-green': item.send_email_status == 'success',
                    'bg-white': item.send_email_status == 'new',
                    'bg-tertiary/30 border-tertiary': item.send_email_status == 'sending',
                    'bg-error/30 border-error': item.send_email_status == 'error'
                }">
                                {{ eventTicketStore.getStatusText(item.send_email_status) }}
                            </span>
                        </td>
                        <td class="p-2 hover:cursor-pointer">
                            <img v-if="item.qr_code_url" @click.prevent="refQrModal.open(item.qr_code_url)"
                                :src="item.qr_code_url" class="w-12 h-12" loading="lazy" />
                        </td>
                        <td class="p-2">
                            <div class="flex gap-2 items-center justify-center">
                                <button type="button" class="p-1.5 text-error group hover:opacity-80"
                                    @click.prevent="deleteItem = item.id; confirmDeleteModal.open();">
                                    <TrashIcon
                                        class="w-5 h-5 group-hover:scale-125 transition-all duration-300 delay-75 ease-in-out cursor-pointer" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
                <tr v-else-if="!$root.overlay.loading">
                    <td class="p-2 text-center text-xs text-grey" :colspan="eventTicketStore.headers.length + 1">
                        データがありません
                    </td>
                </tr>
            </tbody>
        </table>

        <TablePagination :onPageChange="fetchData" :total="eventTicketStore.pagination.total"
            v-model:currentPage="eventTicketStore.filter.page" :perPage="eventTicketStore.pagination.perPage" />

    </div>
    <EventTicketModel ref="refEventTicketModel" />
    <QrItemModal ref="refQrModal" />
    <ImportModal ref="refImportModal" />
    <ConfirmModal ref="confirmDeleteModal" title="ゲスト削除確認" message="このゲストを削除しますか?" :confirm="deleteEventTicket" />
    <SendEmailModal ref="sendEmailModal" />
</template>
