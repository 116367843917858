const TextHelper = {
  toUpperCase: function (text) {
    return text.toUpperCase();
  },
  toLowerCase: function (text) {
    return text.toLowerCase();
  },
  getGenderJp: function(key) {
    if (key === 'male') {
      return '男性';
    }

    if (key === 'female') {
      return '女性';
    }

    if (key === 'other') {
      return 'その他';
    }

    return key
  },
  getTextRelationship: function(key) {
    if (key === 'Father') {
      return '父';
    }

    if (key === 'Mother') {
      return '母';
    }

    if (key === 'Preschool') {
      return '未就学児';
    }

    if (key === 'Brother') {
      return '兄';
    }

    if (key === 'Sister') {
      return '姉';
    }

    if (key === 'Other') {
      return '他'
    }

    return key;
  },
  // encode text for search with get request
  encodeText: function(text) {
    return encodeURIComponent(text);
  },
  // get text for show avatar
  // 2 characters
  // if have space, get first character of each word -> max 2 firt characters 
  // else get 2 first characters
  getAvatarText: function(text) {
    let result = '';
    let words = text.split(' ');
    if (words.length > 1) {
      result = words[0].charAt(0) + words[1].charAt(0);
    } else {
      result = text.substring(0, 2);
    }

    return result.toUpperCase();
  },
}

export default TextHelper;
