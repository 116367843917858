<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
import { MagnifyingGlassIcon, ArrowUturnLeftIcon } from '@heroicons/vue/24/outline';

import dayjs from 'dayjs'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { useEntranceStore } from 'store/entrance';
const entranceStore = useEntranceStore()

import { useRouter } from "vue-router";
const router = useRouter();

import TablePagination from 'views/components/TablePagination.vue';

onMounted(async () => {
  if (entranceStore.item.uid > 0) {
    entranceStore.filterDetail = { from: '', to: '', uid: 0, page: 1, limit: 20 }
    fetchData()
  }
  else {
    router.push({ path: `/entrance` });
  }
})

const _item = ref({})
const guest_image = ref('')
const _name = ref('')

const fetchData = async () => {
  $root.overlay.loading = true
  await entranceStore.getItemDetail()
  $root.overlay.loading = false

  if (entranceStore.detailItems.length > 0) {
    _item.value = entranceStore.detailItems[0]
  }
  else {
    _item.value = entranceStore.item
  }
  await showName()


  if (entranceStore.item.type == 'guest') {
    entranceStore.detailItems.every(item => {
      if (item.guest_image) {
        guest_image.value = item.guest_image
        return
      }
    })
  }
}

const handleDateFrom = (val) => {
  entranceStore.filterDetail.from = val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
}
const handleDateTo = (val) => {
  entranceStore.filterDetail.to = val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
}

const format = (date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}

const doSearch = () => {
  entranceStore.filterDetail.page = 1
  fetchData()
}
const changePagination = () => {
  fetchData()
}

const showName = async () => {
  _name.value = entranceStore.showName(_item.value)
}
const showDetail = (item) => {
  if (entranceStore.item.type != 'guest') {
    return
  }
  _item.value = item
  showName()
  if (item.guest_image) {
    guest_image.value = item.guest_image
  }
}
</script>
<template>
  <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">
    <div class="w-full">
      <button @click="router.push({ path: `/entrance` })" type="button"
        class="flex flex-row p-1 items-center group justify-center rounded rounded-mb bg-background hover:opacity-80 min-w-20 border text-sblue gap-1">
        <ArrowUturnLeftIcon class="w-4 h-4 transition duration-300 delay-75 ease-in-out group-hover:scale-110" />
        戻る
      </button>
    </div>
    <div class="flex flex-row w-full">
      <div class="lg:w-3/12 w-full">
        <div class="flex flex-col gap-4 items-start bg-background min-h-full p-4 rounded-sm">
          <div>
            <div>名前: {{ _name }}</div>
            <div v-if="_item.type == 'guest'">電話番号: {{ _item.guest_phone }}</div>
            <div v-else-if="_item.type == 'parent'">電話番号: {{ _item.parent_phone }}</div>
            <div v-else-if="_item.type == 'club'">電話番号: {{ _item.club_phone }}</div>
          </div>
          <div v-if="entranceStore.item.type == 'guest'">
            <img :src="guest_image" class=" max-w-full" />
          </div>
        </div>
      </div>
      <div class="lg:w-9/12  w-full ml-4 flex flex-col gap-4">
        <div class="w-full gap-4 flex flex-row items-center">
          <div>
            <VueDatePicker v-model="entranceStore.filterDetail.from" time-picker-inline :format="format" locale="ja" input-class-name="h-8"
              cancelText="キャンセル" selectText="選択" placeholder="----/--/-- --:--" @update:model-value="handleDateFrom">
            </VueDatePicker>
          </div>
          <div>から</div>
          <div>
            <VueDatePicker v-model="entranceStore.filterDetail.to" time-picker-inline :format="format" locale="ja" input-class-name="h-8"
              cancelText="キャンセル" selectText="選択" placeholder="----/--/-- --:--" @update:model-value="handleDateTo">
            </VueDatePicker>
          </div>
          <div>
            <button
              class="px-4 py-1 h-[30px] bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
              @click.prevent="doSearch">
              <MagnifyingGlassIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
              検索
            </button>
          </div>
        </div>

        <div class="flex flex-col w-full  gap-4">
          <table class="w-full table">
            <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
              <tr>
                <th class="font-normal p-2 text-left">入校日時</th>
                <th class="font-normal p-2 text-left">出校日時</th>
                <th class="font-normal p-2 text-left">入出校目的</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="entranceStore.detailItems.length" v-for="(item, index) in entranceStore.detailItems">
                <tr class="border-b border-b-gray-200 hover:bg-gray-200 hover:cursor-pointer"
                  @click.prevent="showDetail(item)" :class="{
                    'bg-[#FDFAE2]': item.checkout_time,
                    'bg-[#E9FEEE]': !item.checkout_time,
                  }">
                  <td class="p-2">{{ item.checkin_time }}</td>
                  <td class="p-2">{{ item.checkout_time }}</td>
                  <td class="p-2 text-left">{{ item.reason }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="p-2 text-center text-xs text-grey" :colspan="3">
                    データがありません
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <TablePagination :onPageChange="changePagination" :total="entranceStore.paginationDetail.total"
            v-model:currentPage="entranceStore.filterDetail.page" :perPage="entranceStore.paginationDetail.perPage" />
        </div>

      </div>
    </div>
  </div>
</template>
