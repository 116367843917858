<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useSendEmailTicket } from '../../../js/store/send-ticket-email'

const sendEmailTicketStore = useSendEmailTicket()
</script>
<template>
  <Popover class="relative">
    <PopoverButton class="p-1 bg-primary rounded-lg text-white group" title="追加">
      <PlusIcon class="w-4 h-4 group-hover:rotate-90 transition-all duration-300 delay-75 ease-in-out" />
    </PopoverButton>
    <PopoverPanel class="absolute z-10">
      <div class="mt-1 max-w-screen min-w-40 min-h-20 border-t-2 border-t-secondary border-b border-x border-gray-200 bg-gray-100 p-2 flex flex-col gap-px max-h-64 overflow-y-auto scroll-smooth">
        <template v-for="group in sendEmailTicketStore.groups" >
          <div 
            v-if="!sendEmailTicketStore.data.groups.includes(group.id)"
            class="flex items-center w-full justify-between bg-gray-200/80 rounded-lg px-2 py-1 gap-1 hover:bg-gray-200 cursor-pointer transition-all duration-300 delay-75 ease-in-out group"
            @click.prevent="sendEmailTicketStore.addGroup(group.id)"
          >
            <button class="flex items-center justify-center" title="追加">
              <PlusIcon class="w-4 h-4 text-gray-400 group-hover:rotate-90 group-hover:text-primary transition-all duration-300 delay-75 ease-in-out" />
            </button>
            <div class="grow text-left items-center">
              {{ group.name }}
            </div>
          </div>
        </template>
      </div>
    </PopoverPanel>
  </Popover>
</template>
