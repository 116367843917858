<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useStaff } from 'store/staff';
const staffStore = useStaff()

const file = ref(null)
const isOpen = ref(false)

const open = async () => {
  isOpen.value = true
  file.value = null
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })

const handleFileUpload = (event) => {
  file.value = event.target.files[0];
}

const submitFile = async () => {
  $root.overlay.loading = true

  let formData = new FormData();
  formData.append('file', file.value);

  staffStore.import(formData).then((response) => {
    if (response.status == 200) {
      $root.push.success(response.data.message)
      staffStore.getItems()
    } else {
      $root.push.error(response.data.message)
    }
    $root.overlay.loading = false
    close()
  })
    .catch((err) => {
      close()
      $root.push.error('エラーが発生しましたし。ばらくしてからもう一度お試しください。')
      $root.overlay.loading = false
    });
}

</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4">
              <div class="mt-2 flex flex-col gap-2">
                <!-- upload csv file -->
                <label class="flex flex-col">
                  <span>ECXELファイル</span>
                  <input type="file" accept=".xlsx" class="w-full px-2 py-1" @change="handleFileUpload" />
                </label>
              </div>

              <div class="flex justify-center gap-2 mt-4">
                <button @click.once="submitFile" :disabled="file == null"
                  class="px-4 py-1 bg-primary shadow text-white rounded hover:opacity-80">
                  インポート
                </button>
                <button class="px-4 py-1 border rounded hover:opacity-80" @click.prevent="close">
                  キャンセル
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
