<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useEventTicketEmail } from '../../../js/store/event-ticket-email'
import { Switch } from '@headlessui/vue'
import { ChevronDoubleRightIcon } from '@heroicons/vue/24/outline'
import EditorContent from '../../components/EditorContent.vue'
import APPJSH from '../../../js/helper/ConstantHelper'
import SendEmailHelpModal from './SendEmailHelpModal.vue'

const sendEmailHelpModal = ref(null)

const $root = getCurrentInstance()?.appContext.config.globalProperties
const eventTicketEmailStore = useEventTicketEmail()

onMounted(async () => {
   $root.overlay.loading = true
   await eventTicketEmailStore.getItems()
   $root.overlay.loading = false
})

const handleClick = (item) => {
   if (!item.type) {
      item.type = APPJSH.EMAIL_TEMPLATE_TYPES.HTML
   }
   eventTicketEmailStore.setItem(item)
}

const handleSwitch = (item) => {
  eventTicketEmailStore.saveItemActive(item)
}

const addTemplate = async () => {
   if (!eventTicketEmailStore.item.name.trim()) {
      $root.push.error('設定名を入力してください')
      return
   }

   $root.overlay.loading = true
   await eventTicketEmailStore.addItem()
   $root.overlay.loading = false

   $root.push.success('新規テンプレートを追加しました。')

   handleClick(eventTicketEmailStore.item)
}

const handleSave = async () => {
   if (!eventTicketEmailStore.selected.id) {
      $root.push.error('テンプレートを選択してください')
      return
   }

   if (!eventTicketEmailStore.selected.name.trim()) {
      $root.push.error('設定名を入力してください')
      return
   }

   if (eventTicketEmailStore.selected.content_html.trim() === '') {
      $root.push.error('HTMLメールを入力してください')
      return
   }

   $root.overlay.loading = true
   await eventTicketEmailStore.saveItem()
   $root.overlay.loading = false

   $root.push.success('設定を保存しました')
}
</script>
<template>
   <div class="w-full bg-white rounded-md p-4 flex justify-center gap-4">
    <div class="flex flex-col border-t-2 border-t-secondary w-1/3">
      <h3 class="px-2 py-1 bg-gray-100 border-b">設定名</h3>
      <div class="flex px-2 py-1 items-center gap-2">
         <div class="grow">
            <input 
               v-model="eventTicketEmailStore.item.name" 
               type="text" 
               class="w-full px-2 py-0.5 rounded border bg-yellow" 
            />
         </div>
         <div class="flex-none">
            <button 
               type="button"
               class="px-4 py-1 bg-primary text-white rounded-md font-semibold"
               @click.prevent="addTemplate"
            >
               新規登録
            </button>
         </div>
      </div>
      <hr />
      <div class="flex flex-col border-t-2 border-t-secondary mt-2">
         <table>
            <thead class="bg-gray-100 border-b">
               <tr>
                  <th 
                     v-for="header in eventTicketEmailStore.headers"
                     class="px-2 py-px bg-gray-100 font-normal text-left"
                     :class="header.class"
                  >
                     {{ header.text }}
                  </th>
               </tr>
            </thead>
            <tbody class="divide-y">
               <template v-if="eventTicketEmailStore.items.length">
                  <tr 
                     v-for="item in eventTicketEmailStore.items" 
                     class="cursor-pointer transition-all delay-75 duration-300 ease-in-out"
                     @click="handleClick(item)"
                     :class="{
                        'bg-primary/5': item.active && item.id === eventTicketEmailStore.selected.id
                     }"
                  >
                     <td class="px-2 py-1">
                        <div class="flex items-center">
                           <Switch 
                              class="relative inline-flex h-6 w-11 items-center rounded-full"
                              :class="{
                                 'bg-primary': item.active,
                                 'bg-gray-200': !item.active
                              }"
                              @update:modelValue="handleSwitch(item)"
                           >
                              <span
                                 class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                                 :class="{
                                    'translate-x-6': item.active,
                                    'translate-x-1': !item.active
                                 }"
                              />
                           </Switch>
                        </div>
                     </td>
                     <td 
                        class="px-2 py-1"
                        :class="{
                           'text-gray-400': !item.active,
                           'text-primary': item.active && item.id === eventTicketEmailStore.selected.id
                        }"
                     >
                        <div class="flex items-center justify-between">
                           {{ item.name }}
                           <ChevronDoubleRightIcon v-if="item.id === eventTicketEmailStore.selected.id" class="h-5 w-5" />
                        </div>
                     </td>
                  </tr>
               </template>
               <tr v-else-if="!$root.overlay.loading">
                  <td 
                     class="px-2 py-1 text-center text-xs text-grey" 
                     :colspan="eventTicketEmailStore.headers.length"
                  >
                     データがありません
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
    </div>
    <div class="flex flex-col border border-t-2 border-t-secondary w-2/3">
      <h3 class="px-2 py-1 bg-gray-100 border-b flex justify-between items-center font-semibold">
         テンプレート
         <button 
            type="button"
            class="px-4 py-px bg-tertiary text-white rounded-md"
            @click.prevent="handleSave"
         >
            設定を保存
         </button>
      </h3>
      <!-- name -->
      <div class="flex items-center gap-4 px-2 py-1">
         <div class="flex-none w-fit">
            設定名
         </div>
         <div class="grow">
            <input 
               v-model="eventTicketEmailStore.selected.name" 
               type="text" 
               class="w-full px-2 py-0.5 rounded border bg-yellow max-w-lg" 
            />
         </div>
      </div>
      <hr />
      <div class="flex flex-col h-full">
         <div class="transition-all delay-75 duration-300 ease-in-out h-full">
            <EditorContent 
               v-show="eventTicketEmailStore.selected.type === APPJSH.EMAIL_TEMPLATE_TYPES.HTML"
               v-model:content="eventTicketEmailStore.selected.content_html" 
               class="flex flex-col h-full bg-yellow" 
            />
         </div>
      </div>
      <div class="px-2 text-xs text-error pb-2">
        <p>
          ※メールの内容を入力してください。メールの内容には、<strong>[#FRIENDS]</strong>, <strong>[#FRIEND-NAME]</strong>, <strong>[#FRIEND-QRCODE]</strong>, <strong>[#END-FRIENDS]</strong> のショートコードを使用できます。
          <!-- more -->
          <button 
            type="button"
            class="text-blue-500 underline"
            @click.prevent="sendEmailHelpModal.open()"
          >
            詳細
          </button>
        </p>
      </div>
    </div>
    <SendEmailHelpModal ref="sendEmailHelpModal" />
   </div>
</template>
