import { defineStore } from 'pinia'
import ValidateHelper from 'helper/ValidateHelper'
export const useStaff = defineStore('staff', {
  state: () => ({
    // list
    headers: [
      { text: 'ID', field: 'id' },
      { text: '名前', field: 'name' },
      { text: '位置', field: 'position' },
      { text: '性別', field: 'gender' },
      { text: 'メールアドレス', field: 'email' },
      { text: '電話番号', field: 'phone' },
      { text: '生年月日', field: 'date_of_birth', },
      { text: '現住所', field: 'address', },
      { text: '状態', field: 'status', },
      { text: 'QR_Code', field: 'qr_code_url', },
    ],
    items: [],
    item: {},
    intItem: { id: 0, staff_position_id: 1, kanji_name: '', romaji_name: '', gender: '', email: '', phone: '', date_of_birth: '', address: '', qr_code_url: '', status: 1 },
    filter: { text: '', position: '', page: 1, limit: 20 },
    pagination: { total: 0, currentPage: 1, perPage: 1 },
    error: {},
    positions: [],
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/staff-manager?text=${this.filter.text}&position=${this.filter.position}page=${this.filter.page}&limit=${this.filter.limit}`)
      this.items = []
      this.pagination = { total: 0, currentPage: 1, perPage: 1 }
      //assign value
      if (response.status == 200 && response.data.total > 0) {
        this.items = response.data.data
        this.pagination.total = response.data.total
        this.pagination.currentPage = response.data.current_page
        this.pagination.perPage = response.data.per_page
      }
    },
    async fetchPositions() {
      const response = await axios.get(`/api/staff/positions`)
      this.positions = response.data ?? [];
    },

    async save() {
      const response = await axios.post(`/api/staff-manager`, this.item)
      if (response.data.code == 200) {
        this.getItems()
      }
      return response.data
    },

    async regenQRCode() {
      const response = await axios.get(`/api/staff-manager/regen-qrcode`)
      return response.data
    },

    async validateStaff() {
      this.error = {}
      if (ValidateHelper.isEmpty(this.item.kanji_name)) {
        this.error.kanji_name = true
      }
      if (ValidateHelper.isEmpty(this.item.romaji_name)) {
        this.error.romaji_name = true
      }
      if (ValidateHelper.isEmpty(this.item.gender)) {
        this.error.gender = true
      }
      if (!ValidateHelper.isEmpty(this.item.email) && !ValidateHelper.isEmail(this.item.email)) {
        this.error.email = true
      }
    },
    async validatePosition(arrPositions) {
      this.error = {};
      const nameSet = new Set();

      arrPositions.forEach((item) => {
        if (ValidateHelper.isEmpty(item.name)) {
          this.error[item.name] = true;
        } else if (nameSet.has(item.name)) {
          this.error[item.name] = true;
        } else {
          nameSet.add(item.name);
        }
      });
    },
    async savePositions(arrPositions) {
      const response = await axios.post(`api/staff/positions`, arrPositions)
      return response.data
    },
    async searchStaff(phone) {
      const response = await axios.post('/api/staff/search', { phone: phone })
      return response.data;
    },

    async import(formData) {
      const response = await axios.post('/api/staff/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response
    }
  }
})
