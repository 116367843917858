<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useSendEmailTicket } from '../../../js/store/send-ticket-email'

const sendEmailTicketStore = useSendEmailTicket()

const setTemplate = (id, close) => {
  sendEmailTicketStore.setTemplate(id)

  close()
}
</script>
<template>
  <Popover class="relative" v-slot="{ close }">
    <PopoverButton class="w-fit px-2 py-1 rounded-t-lg bg-gray-100 max-w-lg flex items-center gap-2 border-x border-t border-gray-300 text-xs">
      <span v-if="!sendEmailTicketStore.data.template">テンプレートを選択</span>
      <span v-else>{{ sendEmailTicketStore.getTemplateNameById() }}</span>
      <ChevronDownIcon class="w-4 h-4" />
    </PopoverButton>
    <PopoverPanel class="absolute z-10 translate-x-1/2">
      <div class="mt-1 max-w-screen min-w-40 min-h-20 border-t-2 border-t-secondary border-b border-x border-gray-200 bg-gray-100 p-2 flex flex-col gap-px max-h-64 overflow-y-auto scroll-smooth divide-y">
        <template v-for="temp in sendEmailTicketStore.templates" >
          <div class="flex items-center justify-between cursor-pointer w-full bg-gray-200/80 rounded gap-1 hover:bg-gray-200 transition-all duration-300 delay-75 ease-in-out group px-2 py-1"
            @click="setTemplate(temp.id, close)"
          >
            {{ temp.name }}
            <button v-if="sendEmailTicketStore.data.template === temp.id"
              class="flex items-center justify-center"
            >
              <CheckIcon 
                class="w-4 h-4 group-hover:text-primary transition-all duration-300 delay-75 ease-in-out text-primary"
              />
            </button>
          </div>
        </template>
      </div>
    </PopoverPanel>
  </Popover>
</template>
