import { defineStore } from 'pinia'

export const useSendEmailTicket = defineStore('send-email-ticket', {
  state: () => ({
    groups: [],
    templates: [],
    config: { name: '', email: ''},
    sending: false,
    data: { groups: [], subject: '', body: '', template: 0, sendNewOnly: true },
    previewMode: false,
  }),
  actions: {
    async getConfig() {
      const response = await axios.get('/api/event-email-config')

      this.config = response.data.data.to
      this.groups = response.data.data.groups
      this.templates = response.data.data.templates
    },
    async sendEmail() {
      this.sending = true
      await axios.post('/api/event-email-send', this.data)
      this.sending = false

      this.data = { groups: [], subject: '', body: '', template: 0, sendNewOnly: true }
    },
    addGroup(id) {
      if (!this.data.groups.includes(id)) {
        this.data.groups.push(id)
      }
    },
    removeGroup(id) {
      this.data.groups = this.data.groups.filter(group => group != id)
    },
    getGroupNameById(id) {
      return this.groups.find(group => group.id == id).name ?? ''
    },
    setTemplate(id) {
      this.data.template = id

      // update subject and body
      const template = this.templates.find(template => template.id == id)
      if (template) {
        this.data.subject = template.name
        this.data.body = template.content_html
      }
    },
    getTemplateNameById() {
      const id = this.data.template
      return this.templates.find(template => template.id == id).name ?? ''
    },
  },
})
