<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogTitle, DialogPanel } from '@headlessui/vue'

const $root = getCurrentInstance()?.appContext.config.globalProperties


import { PlusCircleIcon, CalendarIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
import dayjs from 'dayjs'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { useEventTicket } from 'store/event-ticket';
const eventTicketStore = useEventTicket()

const isOpen = ref(false)
const open = async () => {
  eventTicketStore.error = {}
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async () => {
  $root.overlay.loading = true

  // validate required fields  
  await eventTicketStore.validateStaff()
  if (Object.entries(eventTicketStore.error).length > 0) {
    $root.push.error('必須項目を入力してください')
    $root.overlay.loading = false
    return
  }

  eventTicketStore.save().then((result) => {
    if (result.code == 200) {
      $root.push.success('保存しました。')
    }
    else {
      $root.push.error('エラーが発生しました')
    }
    close()
  })
    .catch((err) => {
      close()
      $root.push.error('エラーが発生しました')
    });

  $root.overlay.loading = false
}
defineExpose({ open, close })

const handleDOB = (val) => {
  eventTicketStore.item.date_of_birth = val ? dayjs(val).format('YYYY-MM-DD') : ''
}
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-lg transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all p-4 border-t-2 border-t-secondary">
              <div class="mt-2 flex flex-col gap-2">
                <div class="grid grid-cols-2 gap-2">
                  <div class="flex flex-col">
                    <label class="text-sm text-gray-500">氏名（漢字） <span class="text-error">*</span></label>
                    <input v-model="eventTicketStore.item.kanji_name" type="text" maxlength="255"
                      :class="eventTicketStore.error.kanji_name ? 'border-rose-500' : 'border-gray-300'"
                      class="w-full border rounded p-2" />
                  </div>
                  <div class="flex flex-col">
                    <label class="text-sm text-gray-500">氏名（ローマ字） <span class="text-error">*</span></label>
                    <input v-model="eventTicketStore.item.romaji_name" type="text" maxlength="255"
                      :class="eventTicketStore.error.romaji_name ? 'border-rose-500' : 'border-gray-300'"
                      class="w-full border rounded p-2" />
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-2">
                  <div class="flex flex-col">
                    <label for="name" class="text-sm text-gray-500">携帯番号</label>
                    <div class="relative">
                      <PhoneIcon class="w-6 h-[38px] text-primary absolute pl-2" />
                      <input v-model="eventTicketStore.item.phone" type="text"
                        class="w-full border border-gray-300 rounded p-2 pl-8" />
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <label for="name" class="text-sm text-gray-500">メールアドレス</label>
                    <div class="relative">
                      <EnvelopeIcon class="w-6 h-[38px] text-primary absolute pl-2" />
                      <input v-model="eventTicketStore.item.email" type="text"
                        :class="eventTicketStore.error.email ? 'border-rose-500' : 'border-gray-300'"
                        class="w-full border rounded p-2 pl-8" />
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-4">

                  <div class="flex flex-col">
                    <label class="text-sm text-gray-500">グループ <span class="text-error">*</span></label>
                    <div class="flex flex-row items-center gap-2 w-full">
                      <div class="grow max-h-[100px] overflow-auto border"
                        :class="eventTicketStore.error.groups ? 'border-rose-500' : 'border-gray-300'">
                        <ul class="p-2 flex flex-col gap-2">
                          <li class="listItems" v-for="(group, index) in eventTicketStore.groups">
                            <label>
                              <input v-model="eventTicketStore.item.groups" id="index" :value="group.id"
                                type="checkbox">
                              {{ group.name }}
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div class="flex flex-none items-end justify-end">
                        <router-link class="group" title="グループ新規登録" to="/event-group" target="_blank">
                          <PlusCircleIcon
                            class="w-5 h-5 text-primary cursor-pointer transition-all delay-75 duration-300 ease-in-out hover:rotate-90" />
                        </router-link>
                      </div>
                    </div>

                  </div>
                  <div class="flex flex-col">
                    <label for="name" class="text-sm text-gray-500">生年月日</label>
                    <VueDatePicker v-model="eventTicketStore.item.date_of_birth" auto-apply format="yyyy-MM-dd"
                      :enable-time-picker="false" locale="ja" :teleport="true" placeholder="----/--/--"
                      @update:model-value="handleDOB" input-class-name="p-2 border border-gray-300 rounded">
                      <template #input-icon>
                        <CalendarIcon class="ml-2 w-4 h-4 text-primary" />
                      </template>
                    </VueDatePicker>
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-2">
                  <div class="flex flex-col">
                    <label class="text-sm text-gray-500">性別</label>
                    <select v-model="eventTicketStore.item.gender"
                      :class="eventTicketStore.error.gender ? 'border-rose-500' : 'border-gray-300'"
                      class="p-2 border rounded">
                      <option value="男性">男性</option>
                      <option value="女性">女性</option>
                    </select>
                  </div>
                  <div class="flex flex-col">
                    <label class="text-sm text-gray-500">状態</label>
                    <select v-model="eventTicketStore.item.status" class="p-2 border border-gray-300 rounded">
                      <option value="1">有効</option>
                      <option value="0">無効</option>
                    </select>
                  </div>
                </div>

                <div class="flex flex-col">
                  <label class="text-sm text-gray-500">現住所</label>
                  <input v-model="eventTicketStore.item.address" type="text" maxlength="255"
                    class="w-full border border-gray-300 rounded p-2" />
                </div>

                <div class="flex flex-col">
                  <label class="text-sm text-gray-500">備考</label>
                  <textarea v-model="eventTicketStore.item.note" class="w-full border border-gray-300 rounded p-2" maxlength="255"/>
                </div>

                <div class="flex items-center gap-4 justify-center pt-4">
                  <button type="button" class="p-2 rounded-md bg-primary text-white hover:opacity-80 min-w-20"
                    @click="save">
                    {{ eventTicketStore.item.id > 0 ? '更新' : '登録する' }}
                  </button>
                  <button type="button" class="p-2 rounded-md bg-gray-100 text-gray-800 hover:opacity-80"
                    @click="close">
                    キャンセル
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style>
.border-error input {
  border-color: #f43f5e;
}
</style>
