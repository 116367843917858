<script setup>
import { ref, onMounted } from 'vue';
import {ChevronDownIcon, PencilIcon } from '@heroicons/vue/24/outline';
import ModalRoom from './ModalRoom.vue';
import { useRoom } from '../../../js/store/room';

const roomStore = useRoom()
const modalRoom = ref(null);
onMounted(() => {
  roomStore.getItems();
})
const openModalRoom = (item) => {
  roomStore.item = JSON.parse(JSON.stringify(item))
  modalRoom.value.open();
}
</script>
<template>
  <table class="w-full table border-b divide-y">
    <thead class="border-t-2 border-t-secondary bg-gray-100">
      <tr>
        <th class="text-left p-1" colspan="2">
          <div class="flex items-center gap-1">
            <ChevronDownIcon class="w-5 h-5 text-gray-500" />
            <div class="flex items-center font-semibold text-primary">施設 <span class="text-gray-400 font-normal">(3)</span></div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y">
      <tr v-for="clss in roomStore.items">
        <td class="p-1">
          {{ clss.name }}
        </td>
        <td class="p-1">
          <button class="p-1.5 rounded-full bg-green" @click.prevent="openModalRoom(clss)">
            <PencilIcon class="w-4 h-4 text-white" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ModalRoom ref="modalRoom" />
</template>
