import { defineStore } from 'pinia'

export const useResource = defineStore('resource', {
  state: () => ({
    item: {},
    itemsTime: [],
    items: [],
    expanded: true,
    search: '',
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/resources`);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    },
    async getTimeItems() {
      const response = await axios.get(`/api/get_resources-time`);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.itemsTime = response.data;
    },
  },
  getters: {
    searchItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
})
