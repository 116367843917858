<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { useHoliday } from '../../../js/store/holiday';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DateTimeHelper from '../../../js/helper/DatetimeHelper'

const holidayStore = useHoliday()
const $root = getCurrentInstance()?.appContext.config.globalProperties

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save =  async() => {
  $root.overlay.loading = true
  holidayStore.item.date = DateTimeHelper.format(holidayStore.item.date);
  try {
      const response = await axios.post('/api/holidays', holidayStore.item);
      if(response.status == 200) {
        $root.push.success(response.data.message)
        holidayStore.getItems();
        close();
      } else {
        $root.push.error(response.data.message)
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
    $root.overlay.loading = false
    return
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-sm transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary"
            >
              <div class="flex flex-col gap-2 p-4">
                <div class="flex flex-col">
                  日付
                  <div class="relative">
                    <CalendarDaysIcon class="absolute w-4 h-4 top-1/2 left-2 transform -translate-y-1/2 text-primary z-10" />
                    <VueDatePicker 
                      v-model="holidayStore.item.date" 
                      auto-apply 
                      format="yyyy-MM-dd"
                      :enable-time-picker="false"
                      locale="ja"
                      class="mydatepicker w-fit"
                      :clearable="false"
                      input-class-name="bg-white border rounded w-36 pl-8 pr-2 py-1 cursor-pointer"
                    >
                      <template #dp-input="{ value }">
                        <input type="text" :value="value" class="bg-white border rounded w-36 pl-8 pr-2 py-1 cursor-pointer" />
                      </template>
                    </VueDatePicker>
                  </div>
                </div>
                <div class="flex flex-col">
                  <label>休日名<span class="text-red font-bold">(*)</span></label>
                  <div>
                    <input v-model="holidayStore.item.name" type="text" class="w-full border border-gray-300 rounded px-2 py-1" />
                  </div>
                </div>
                <div class="flex flex-col">
                  有効
                  <div>
                    <select v-model="holidayStore.item.status" class="w-full border border-gray-300 rounded px-2 py-1">
                      <option value="1">有効</option>
                      <option value="0">無効</option>
                    </select>
                  </div>
                </div>
                <div class="flex items-center gap-2 w-full mt-4 font-semibold">
                  <div class="w-1/2">
                    <button class="px-4 py-2 bg-primary text-white rounded-md w-full" @click.prevent="save">保存</button>
                  </div>
                  <div class="w-1/2">
                    <button class="px-4 py-2 border border-gray-400 bg-gray-100 rounded-md w-full" @click.prevent="close">キャンセル</button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
