import { defineStore } from 'pinia'
import APPJSH from '../helper/ConstantHelper'

export const useConfigStore = defineStore('configuration', {
  state: () => ({
    /* email config - メール送信設定 */
    emailConfig: {
      from: '',       // メールアドレス (※必須)
      name: '',       // 差出人名 (※必須)
      host: '',       // ホスト名 (※必須)
      username: '',   // ホスト名
      password: '',   // パスワード
      port: '',       // ポート番号 (※必須)
      encryption: APPJSH.EMAIL_CRYPTS.TLS, // 暗号化の種類 (※必須)
    },
  }),
  actions: {
    async getConfig(task) {
      // make default value
      this.emailConfig = {
        from: '',       // メールアドレス (※必須)
        name: '',       // 差出人名 (※必須)
        host: '',       // ホスト名 (※必須)
        username: '',   // ホスト名
        password: '',   // パスワード
        port: '',       // ポート番号 (※必須)
        encryption: APPJSH.EMAIL_CRYPTS.TLS, // 暗号化の種類 (※必須)
      }

      const response = await axios.get(`/api/setting/${task}`)

      if (response.status === 200 && response.data.data.host) {
        this.emailConfig = response.data.data
      }
    },
    async updateConfig(task) {
      try {
        const response = await axios.post(`/api/setting`, {
          'field_key': task,
          'field_value': this.emailConfig
        })
  
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        console.error('エラーが発生しました')
      }
    },
    async validateConfig() {
      // validate email config can be send email or not by send request to server
      try {
        const response = await axios.post(`/api/setting-validate`, {
          'field_key': 'email',
          'field_value': this.emailConfig
        })
  
        if (response.status === 200) {
          if (response.data.status === 'success') {
            return true
          } else {
            return false
          }
        }
      } catch (error) {
        return false
      }
    }
  }
})
