<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PlusCircleIcon, MinusCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useClubRequestStore } from '../../../js/store/club-request';

const clubRequestStore = useClubRequestStore()
</script>
<template>
  <Popover v-slot="{ close }" class="relative flex items-center">
    <PopoverButton class="group">
      <PlusCircleIcon class="w-5 h-5 text-primary cursor-pointer transition-all delay-75 duration-300 ease-in-out hover:rotate-90" />
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute left-1/2 z-10 mt-1 w-screen max-w-96 transform"
      >
        <div class="flex flex-col bg-white shadow-lg border-t-2 border-t-primary">
          <div class="flex w-full bg-gray-100 px-2 py-1 items-center justify-between">
            <h4 class="font-semibold text-primary-500 flex items-center gap-1 ">
              施設追加
            </h4>
            <!-- close button -->
            <button @click.prevent="close">
              <XMarkIcon class="w-6 h-6 text-error cursor-pointer hover:scale-110" />
            </button>
          </div>
          <div class="p-2">
            <div v-if="clubRequestStore.rooms.length" class="grid grid-cols-2 gap-1 max-h-52 overflow-auto">
              <!-- list resources with text and plus icon, if added just show remove icon -->
              <div v-for="room in clubRequestStore.rooms" class="col-span-1">
                <div class="flex items-start gap-1 px-2 py-px rounded bg-gray-100 border hover:shadow group h-full">
                  <div class="flex-none w-fit">
                    <PlusCircleIcon 
                      v-if="!clubRequestStore.item.rooms.includes(room.id)"
                      class="w-5 h-5 text-primary cursor-pointer hover-group:scale-110" 
                      @click.prevent="clubRequestStore.addRoom(room.id)"
                    />
                    <MinusCircleIcon 
                      v-else
                      class="w-5 h-5 text-error cursor-pointer hover-group:scale-110" 
                      @click.prevent="clubRequestStore.removeRoom(room.id)"
                    />
                  </div>
                  <div class="grow truncate">
                    <label :title="room.name" class="flex w-full text-left">{{ room.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex items-center justify-center h-32">
              <div class="flex flex-col items-center gap-2">
                <span class="text-gray-500">施設がありません</span>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
