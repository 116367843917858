<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useResource } from '../../../js/store/resource';
import '@vuepic/vue-datepicker/dist/main.css'

const resourceStore = useResource()
const $root = getCurrentInstance()?.appContext.config.globalProperties

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async() => {
  $root.overlay.loading = true
  try {
    const response = await axios.post('/api/resources', resourceStore.item);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      resourceStore.getItems();
      close();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-sm transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary"
            >
              <div class="flex flex-col gap-2 p-4">
                <div class="flex flex-col">
                  物品名
                  <div>
                    <input v-model="resourceStore.item.name" type="text" class="w-full border border-gray-300 rounded px-2 py-1" />
                  </div>
                </div>
                <div class="flex items-center gap-2">
                  <div class="grow">
                    <div class="flex flex-col">
                      物品総数
                      <div>
                        <input v-model="resourceStore.item.quantity" type="number" class="w-full border border-gray-300 rounded px-2 py-1" />
                      </div>
                    </div>
                  </div>
                  <div class="w-32 flex-none">
                    <div class="flex flex-col">
                      単位
                      <div>
                        <input v-model="resourceStore.item.unit" type="text" class="w-full border border-gray-300 rounded px-2 py-1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  備考
                  <div>
                    <textarea v-model="resourceStore.item.notes" class="w-full border border-gray-300 rounded px-2 py-1" rows="3"></textarea>
                  </div>
                </div>
                <div class="flex items-center gap-2 w-full mt-4 font-semibold">
                  <div class="w-1/2">
                    <button class="px-4 py-1 bg-primary text-white rounded-md w-full" @click.prevent="save">保存</button>
                  </div>
                  <div class="w-1/2">
                    <button class="px-4 py-1 border border-gray-400 bg-gray-100 rounded-md w-full" @click.prevent="close">キャンセル</button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
