<script setup>
import { ref } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['drag', 'drag:cancel', 'drag:save'])

const rangeMin = ref(null)
const rangeMax = ref(null)

const TIME_RANGE = parseInt(import.meta.env.VITE_APP_TIME_RANGE_MIN || 3)

const props = defineProps({
  range: { type: Object, required: true },
  class: {
    type: String,
    default: `
      flex items-center gap-1 rounded-full px-4 py-1 bg-white text-slate-950 shadow-none 
      hover:opacity-80 transition-all duration-300 delay-75 font-normal border border-slate-800
    `
  }
})

const onDrag = (data) => {
  if (
    data.type === 'minValue' &&
    data.value > props.range.maxValue - TIME_RANGE
  ) {
    rangeMin.value.value = props.range.minValue

    return
  }

  if (
    data.type === 'maxValue' &&
    data.value < props.range.minValue + TIME_RANGE
  ) {
    rangeMax.value.value = props.range.maxValue

    return
  }

  emit('drag', data)
}

const cancel = (close) => {
  emit('drag:cancel')
  close()
}
const save = (close) => {
  emit('drag:save')
  close()
}
</script>
<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton :class="class">
      <AdjustmentsHorizontalIcon class="h-5 w-5 fill-slate-950" />
      <slot name="text" />
    </PopoverButton>

    <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel v-slot="{ close }"
        class="absolute z-10 top-[150px] w-screen max-w-sm -translate-y-full overflow-hidden rounded-lg border border-sky-950 bg-white p-6 shadow-lg transition-all delay-75 duration-300 lg:max-w-3xl">
        <div class="flex w-full items-center">
          <div class="w-4 tracking-tight">
            <slot name="label" />
          </div>
          <div class="grow">
            <div class="flex flex-col gap-6 pt-16">
              <div class="relative flex w-full items-center justify-center px-6">
                <div
                  class="pointer-events-none relative h-2 w-full rounded-full bg-violet-100 transition-all delay-75 duration-300 ease-in-out">
                  <div class="pointer-events-none absolute left-0 right-0 h-2 w-full">
                    <div class="pointer-events-none relative transition-all duration-300 ease-in-out" :style="{
                      left:
                        'calc(' + (props.range.minValue - props.range.min) * (100 / (props.range.max - props.range.min)) + '% - '
                        + (props.range.minValue - props.range.min) + 'px)'
                    }">
                      <div
                        class="absolute -top-10 z-[2] -mt-2 flex h-7 w-7 origin-bottom transform items-center justify-center rounded-full bg-violet-600 font-bold text-white transition-transform duration-300 ease-in-out after:absolute after:top-0 after:-z-[1] after:h-7 after:w-7 after:rotate-45 after:rounded-bl-full after:rounded-tl-full after:rounded-tr-full after:bg-violet-600 after:transition-all after:duration-300 after:ease-in-out">
                        {{ props.range.minValue < 24 ? props.range.minValue : props.range.minValue - 24 }} </div>
                      </div>
                      <input type="range" :min="props.range.min" :max="props.range.max" step="1"
                        class="pointer-events-none absolute h-2 w-full cursor-pointer appearance-none rounded-full bg-transparent out-of-range:bg-violet-600 focus:outline-none [&::-moz-range-thumb]:pointer-events-auto [&::-moz-range-thumb]:z-[2] [&::-moz-range-thumb]:h-6 [&::-moz-range-thumb]:w-6 [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-violet-600 [&::-moz-range-thumb]:focus-visible:ring [&::-moz-range-thumb]:focus-visible:ring-violet-100 [&::-ms-thumb]:pointer-events-auto [&::-ms-thumb]:z-[2] [&::-ms-thumb]:h-6 [&::-ms-thumb]:w-6 [&::-ms-thumb]:appearance-none [&::-ms-thumb]:rounded-full [&::-ms-thumb]:bg-violet-600 [&::-ms-thumb]:focus-visible:ring [&::-ms-thumb]:focus-visible:ring-violet-100 [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:z-[2] [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:w-6 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-violet-600 [&::-webkit-slider-thumb]:focus-visible:ring [&::-webkit-slider-thumb]:focus-visible:ring-violet-100"
                        :value="props.range.minValue"
                        @input.prevent="onDrag({ type: 'minValue', value: $event.target.value })" ref="rangeMin" />
                    </div>
                    <div class="pointer-events-none absolute h-2 rounded-full bg-violet-600 transition-all ease-in-out"
                      :style="{
                          left: (props.range.minValue - props.range.min) * (100 / (props.range.max - props.range.min)) + '%',
                          right: (props.range.max - props.range.maxValue) * (100 / (props.range.max - props.range.min)) + '%'
                        }">
                    </div>
                    <div class="pointer-events-none absolute left-0 right-0 h-2 w-full">
                      <div class="pointer-events-none relative transition-all duration-300 ease-in-out" :style="{
                        left:
                          'calc(' + (props.range.maxValue - props.range.min) * (100 / (props.range.max - props.range.min)) + '% - '
                          + (props.range.maxValue - props.range.min) + 'px)'
                      }">
                        <div
                          class="absolute -top-10 z-[2] -mt-2 flex h-7 w-7 origin-bottom transform items-center justify-center rounded-full bg-violet-600 font-bold text-white transition-transform duration-300 ease-in-out after:absolute after:top-0 after:-z-[1] after:h-7 after:w-7 after:rotate-45 after:rounded-bl-full after:rounded-tl-full after:rounded-tr-full after:bg-violet-600 after:transition-all after:duration-300 after:ease-in-out">
                          {{ props.range.maxValue < 24 ? props.range.maxValue : props.range.maxValue - 24 }} </div>
                        </div>
                        <input type="range" :min="props.range.min" :max="props.range.max" step="1"
                          class="pointer-events-none absolute h-2 w-full cursor-pointer appearance-none rounded-full bg-transparent out-of-range:bg-violet-600 focus:outline-none [&::-moz-range-thumb]:pointer-events-auto [&::-moz-range-thumb]:z-[2] [&::-moz-range-thumb]:h-6 [&::-moz-range-thumb]:w-6 [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:bg-violet-600 [&::-moz-range-thumb]:focus-visible:ring [&::-moz-range-thumb]:focus-visible:ring-violet-100 [&::-ms-thumb]:pointer-events-auto [&::-ms-thumb]:z-[2] [&::-ms-thumb]:h-6 [&::-ms-thumb]:w-6 [&::-ms-thumb]:appearance-none [&::-ms-thumb]:rounded-full [&::-ms-thumb]:bg-violet-600 [&::-ms-thumb]:focus-visible:ring [&::-ms-thumb]:focus-visible:ring-violet-100 [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:z-[2] [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:w-6 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-violet-600 [&::-webkit-slider-thumb]:focus-visible:ring [&::-webkit-slider-thumb]:focus-visible:ring-violet-100"
                          :value="props.range.maxValue"
                          @input.prevent="onDrag({ type: 'maxValue', value: $event.target.value })" ref="rangeMax" />
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-center gap-4">
                    <button type="button"
                      class="ease-in-out hover:scale-105 rounded bg-primary px-4 py-2 font-bold text-white shadow-none transition-all delay-75 duration-300 hover:opacity-80"
                      @click="save(close)">
                      絞り込み
                    </button>
                    <button type="button"
                      class="ease-in-out hover:scale-105 rounded bg-gray-100 text-gray-800 px-4 py-2 font-bold shadow-none transition-all delay-75 duration-300 hover:opacity-80"
                      @click="cancel(close)">
                      キャンセル
                    </button>
                  </div>
                </div>
              </div>
            </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
