import { defineStore } from 'pinia'
import DateTimeHelper from '../helper/DatetimeHelper'
import axios, { all } from 'axios'

export const useClubRequestStore = defineStore('club-request', {
  state: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: '名前',
        value: 'name',
      },
      {
        text: '時間',
        value: 'time',
      },
      {
        text: 'クラブ',
        value: 'club',
      },
      {
        text: '承認',
        value: 'approved',
      },
      {
        text: '更新日',
        value: 'updated_at',
      },
      {
        text: '拡張',
        value: 'action',
      }
    ],
    showFilter: false,
    item: {
      id: 0,
      club_id: 0,
      name: '',
      rooms: [],
      resources: {},
      date: '',
      start_at: '',
      end_at: '',
      is_all_day: false,
      repeated: 'none',
      end_repeated_type: 'never',
      end_repeat_at: '',
      note: '',
      is_last_day_of_month: false,
    },
    items: [],
    filter: {
      date: { start: '', end: '' },
      rooms: [],
      resources: [],
    },
    hours: {
      start: 0,
      end: 23,
      hours: Array.from({ length: 24 }, (_, i) => i),
    },
    rooms: [],
    resources: [],
    clubs: [],
  }),
  actions: {
    reset() {
      this.item = {
        id: 0,
        club_id: 0,
        name: '',
        rooms: [],
        resources: {},
        date: '',
        start_at: '',
        end_at: '',
        is_all_day: false,
        repeated: 'none',
        end_repeated_type: 'never',
        end_repeat_at: '',
        note: '',
        is_last_day_of_month: false,
      }
    },
    removeFilter(type, value) {
      if (type === 'date') {
        this.filter.date = { start: '', end: '' }
        return
      }

      this.filter[type] = this.filter[type].filter((i) => i !== value)
    },
    getResources() {
      axios.get('/api/active-resources').then((res) => {
        this.rooms = res.data.rooms
        this.resources = res.data.resources
        this.clubs = res.data.clubs
      })
    },
    addRoom(id) {
      if (!this.item.rooms.includes(id)) {
        this.item.rooms.push(id)
      }
    },
    removeRoom(id) {
      this.item.rooms = this.item.rooms.filter((i) => i !== id)
    },
    getRoomNameById(id) {
      return this.rooms.find((i) => i.id === id)?.name
    },
    getClubNameById(id) {
      return this.clubs.find((i) => i.id === id)?.name
    },
    existsResource(id) {
      if (!this.item.resources.length) {
        return false
      }

      return this.item.resources.find((i) => i.id === id)
    },
    addResource(id) {
      if (!this.item.resources.length) {
        this.item.resources = [{ id, quantity: 1 }]
        return
      }

      if (!this.item.resources.find((i) => i.id === id)) {
        this.item.resources.push({ id, quantity: 1 })
      }
    },
    removeResource(id) {
      if (!this.item.resources.length) {
        return
      }

      this.item.resources = this.item.resources.filter((i) => i.id !== id)
    },
    getResourceNameById(id) {
      return this.resources.find((i) => i.id === id)?.name
    },
    getResourceQtyById(id) {
      return this.resources.find((i) => i.id === id)?.quantity
    },
    getResourceUnitById(id) {
      return this.resources.find((i) => i.id === id)?.unit
    },
    async getItems() {
      const response = await axios.post('/api/club-requests', {
        text: this.filter.text,
        start: this.filter.date.start 
            ? DateTimeHelper.format(this.filter.date.start, 'YYYY-MM-DD HH:mm:ss')
            : '',
        end: this.filter.date.end 
          ? DateTimeHelper.format(this.filter.date.end, 'YYYY-MM-DD HH:mm:ss')
          : '',
        rooms: this.filter.rooms,
        resources: this.filter.resources,
        clubs: this.filter.clubs,
        status: this.filter.status,
      })
      if (response.status === 200) {
        this.items = response.data.data
        if (this.items && this.items.length) {
          // convert date from utc to local time
          this.items = this.items.map((i, idx) => {
            let nameArr = [], nameText = ''
            if (i.resources && i.resources.length) {
              i.resources = i.resources.map((r) => {
                let arr = []
                if (r.room) {
                  arr.push(r.room)
                  nameArr.push(r.room)
                }
                if (r.resource) {
                  arr.push(`${r.resource}x${r.quantity}`)
                  nameArr.push(r.resource)
                }

                return arr
              })

              nameText = nameArr.join(' ')
            }

            if (i.other) {
              nameText += '「予定あり」'
            }

            if (!i.other && i.club && i.club.name) {
              nameText = `${i.club.name} ${nameText}`
            }

            let hoverTextPrefix = `${DateTimeHelper.formatCalendar(i.start_at)}-${DateTimeHelper.formatCalendar(i.end_at)}`
            if (i.is_all_day) {
              nameText = '「終日」' + nameText
              hoverTextPrefix = ''
            }

            const start = DateTimeHelper.format(i.start_at, 'YYYY-MM-DD HH:mm:ss')
            const end = DateTimeHelper.format(i.end_at, 'YYYY-MM-DD HH:mm:ss')
            const hoverText = `${hoverTextPrefix} ${nameText}`

            const evt = {
              title: nameText,
              start: start,
              end: end,
              allDay: i.is_all_day,
              extendedProps: { 
                id: i.id,
                hoverText: hoverText, 
                other: i.other,
              },
              backgroundColor: i.status === 'approved' 
                ? '#37AB9D' : i.status === 'rejected' 
                ? '#F44336' 
                : i.status === 'waitting' ? '#E091EE' 
                : '#FFC042',
            }

            // change text event color to gray
            if (i.other) {
              evt.classNames = ['text-gray-400', `custom-${i.status}`]
            }

            return evt
          })
        }
      }
    },
    async save() {
      const response = await axios.post('/api/club-request', {
        id: this.item.id,
        club_id: this.item.club_id,
        name: this.item.name,
        rooms: this.item.rooms,
        resources: this.item.resources,
        start_at: DateTimeHelper.format(this.item.start_at, 'YYYY-MM-DD HH:mm:ss'),
        end_at: DateTimeHelper.format(this.item.end_at, 'YYYY-MM-DD HH:mm:ss'),
        is_all_day: this.item.is_all_day,
        repeated: this.item.repeated,
        end_repeated_type: this.item.end_repeated_type,
        end_repeat_at: this.item.end_repeated_type === 'date' 
          ? DateTimeHelper.format(this.item.end_repeat_at, 'YYYY-MM-DD HH:mm:ss')
          : '',
        note: this.item.note,
        is_last_day_of_month: this.item.is_last_day_of_month,
      })
      if (response.status === 200) {
        this.item = {
          id: 0,
          club_id: 0,
          name: '',
          rooms: [],
          resources: {},
          date: '',
          start_at: '',
          end_at: '',
          is_all_day: false,
          repeated: 'none',
          end_repeated_type: 'never',
          end_repeat_at: '',
          note: '',
          is_last_day_of_month: false,
        }
      }
    },
    async getItem(id) {
      const response = await axios.get(`/api/club-request/${id}`)
      if (response.status === 200) {
        this.item = response.data.data
        if (this.item.start_at_org) {
          this.item.start_at_org = DateTimeHelper.format(this.item.start_at_org, 'YYYY-MM-DD HH:mm:ss')
        }
        this.item.start_at = DateTimeHelper.format(this.item.start_at, 'YYYY-MM-DD HH:mm:ss')
        this.item.end_at = DateTimeHelper.format(this.item.end_at, 'YYYY-MM-DD HH:mm:ss')
        this.item.updated_at = DateTimeHelper.format(this.item.updated_at, 'YYYY-MM-DD HH:mm:ss')

        if (this.item.end_repeat_at) {
          this.item.end_repeat_at = DateTimeHelper.format(this.item.end_repeat_at, 'YYYY-MM-DD HH:mm:ss')
          this.item.end_repeated_type = 'date'
        } else {
          this.item.end_repeated_type = 'never'
        }

        if (!this.item.club_id) {
          this.item.club_id = 0
        }

        this.item.is_all_day = this.item.is_all_day ? true : false
        this.item.is_last_day_of_month = this.item.is_last_day_of_month ? true : false
      }
    },
    async approve(status) {
      if (!this.item.id) {
        return
      }

      const id = this.item.id
      await axios.post(`/api/club-request/${id}/approve`, {
        id,
        status,
      })
    },
  },
  getters: {
    from() {
      return this.total ? (this.page - 1) * this.perpage + 1 : 0
    },
    to() {
      return this.page * this.perpage > this.total ? this.total : this.page * this.perpage 
    },
  }
})
