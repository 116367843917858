<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
import { EnvelopeIcon } from '@heroicons/vue/24/outline'
import { useRoute } from 'vue-router'

const $route = useRoute()

// get person from url /undoukai/:person
let person = parseInt($route.params.person) || 1
if (person < 1) {
  person = 1
} else if (person > 5) {
  person = 5
}

const gakumens = {
  '小学校': ['小1', '小2', '小3', '小4', '小5', '小6'],
  '中学校': ['中1', '中2', '中3'],
}

const $root = getCurrentInstance()?.appContext.config.globalProperties
const data = ref({
  kanji_name: '',
  email: '',
  school_level: '', // 学校
  grade: '', // 学年
  class_name: '', // 組
})
const qrcodes = ref([])
const isRegistered = ref(false)
const error = ref({})

const register = async () => { 
  $root.overlay.loading = true

  qrcodes.value = []

  await validate()
  if (Object.entries(error.value).length > 0) {
    $root.push.error('必須項目を入力してください')
    $root.overlay.loading = false
    return
  }
  
  try {
    const response = await axios.post('/api/form/qr-request/1', {
      ...data.value,
      people: person,
    });
    if (response.status == 200) {
      qrcodes.value = response.data
      isRegistered.value = true
      $root.overlay.loading = false
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error('エラーが発生しました')
    $root.overlay.loading = false
  }

  isRegistered.value = true
}

const validate = async () => {
  error.value = {}
  // require kanji_name
  if (!data.value.kanji_name) {
    error.value.kanji_name = true
  }
  // validate email format
  if (data.value.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.value.email)) {
    error.value.email = true
  }
}

const downloadQRCode = (qrcode) => {
  // download to memory and share to photos/gallery
  const img = new Image()
  img.src = qrcode.url
  img.onload = () => {
    const canvas = document.createElement('canvas')
    const padding = 10
    canvas.width = img.width + padding * 2
    canvas.height = img.height + 50 + padding * 2 // Add space for the name and padding
    const ctx = canvas.getContext('2d')

    // Add background color
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Draw the image with padding
    ctx.drawImage(img, padding, padding)

    // Add the name below the image with padding
    ctx.font = '20px Arial'
    ctx.fillStyle = 'black'
    const textWidth = ctx.measureText(qrcode.name).width
    const textX = (canvas.width - textWidth) / 2
    ctx.fillText(qrcode.name, textX, img.height + 40 + padding)

    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = qrcode.name + '.png'
      a.click()
      URL.revokeObjectURL(url)
    })
  }
}

const reset = async () => {
  isRegistered.value = false
}
</script>
<template>
  <div class="max-w-screen min-h-svh md:min-h-screen bg-stone-200 w-full flex flex-col items-center justify-center md:p-6 relative">
    <div class="bg-white transition-all delay-75 duration-300 ease-in-out flex flex-col w-full md:min-h-min max-w-xl md:shadow-lg">
      <div 
        class="flex flex-col font-semibold w-full text-black border-t-2 border-t-secondary bg-gray-100 p-4"
      >
        <h5 class="text-lg font-semibold">ハノイ日本人学校運動会参加登録</h5>
        <p class="text-xs">
          2024年10月20日（日）
          <span>8:00 - 12:00</span>
        </p>
      </div>
      <hr />
      
      <div class="flex flex-col">
        <!-- message register success -->
        <div 
          v-show="isRegistered" 
          class="flex items-center gap-1 text-primary p-2 md:p-4 transition-all delay-75 duration-300 ease-in-out bg-primary/30 backdrop-filter backdrop-blur sticky top-0 z-10"
        >
          <div class="flex flex-col font-semibold text-lg">
            <h2 class="text-xs">登録が完了しました</h2>
            <!-- download or capture screen qrcode help text -->
            <span class="text-error">
              必ずQRをダウロード、またはスクリーンショットしてください。
            </span>
          </div>
        </div>

        <div class="flex flex-col p-2 md:p-4">
          <div class="flex flex-col divide-y border">
            <div class="flex flex-col md:flex-row md:items-center bg-stone-100 divide-y md:divide-x md:divide-y-0">
              <div class="flex-none w-40 p-1">
                <div class="flex items-start gap-1 md:justify-end">
                  お名前(生徒の長子)
                  <span v-if="!isRegistered" class="text-error">*</span>
                </div>
              </div>
              <div class="grow p-1 bg-white">
                <div class="w-full">
                  <input 
                    type="text" 
                    v-model="data.kanji_name" 
                    class="px-2 py-1 border rounded w-full disabled:bg-white" 
                    :class="{
                      'border-rose-500': error.kanji_name,
                      'border-gray-300': !error.kanji_name,
                      'appearance-none border-none': isRegistered,
                    }"
                    :disabled="isRegistered"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row md:items-center bg-stone-100 divide-y md:divide-x md:divide-y-0">
              <div class="flex-none w-40 p-1 md:text-right">
                メールアドレス
              </div>
              <div class="grow p-1 bg-white">
                <div class="w-full relative">
                  <EnvelopeIcon v-if="!isRegistered" class="w-4 h-4 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400" />
                  <input 
                    type="email" 
                    v-model="data.email" 
                    class="pr-2 py-1 border rounded w-full disabled:bg-white" 
                    :class="{
                      'border-rose-500': error.email,
                      'border-gray-300': !error.email,
                      'pl-2 appearance-none border-none': isRegistered,
                      'pl-7': !isRegistered,
                    }"
                    :disabled="isRegistered"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row bg-stone-100 divide-y md:divide-x md:divide-y-0">
              <div class="flex-none w-fit md:w-40 p-1 md:pt-2 md:text-right">
                学校
              </div>
              <div class="grow bg-stone-100">
                <div class="flex flex-col divide-y">
                  <div class="flex flex-col md:flex-row md:items-center md:justify-stretch divide-y md:divide-y-0 md:divide-x">
                    <div class="flex-none w-full md:w-fit">
                      <div class="flex items-center p-1 bg-white">
                        <select 
                          v-model="data.school_level" 
                          class="px-2 py-1 border rounded w-full border-gray-300 disabled:text-black transition-all delay-75 duration-300 ease-in-out"
                          :class="{
                            'appearance-none border-none': isRegistered,
                          }"
                          :disabled="isRegistered"
                        >
                          <option value="">選択</option>
                          <option value="小学校">小学校</option>
                          <option value="中学校">中学校</option>
                        </select>
                      </div>
                    </div>
                    <div class="grow">
                      <div class="flex flex-col md:flex-row md:items-center divide-y md:divide-y-0 md:divide-x">
                        <div class="flex flex-col md:flex-row md:items-center divide-y md:divide-y-0 md:divide-x w-full md:w-1/2 md:justify-end">
                          <div class="flex-none w-full md:w-10 p-1 md:text-right">
                            学年
                          </div>
                          <div class="grow">
                            <div class="p-1 bg-white w-full">
                              <select 
                                v-model="data.grade" 
                                class="px-2 py-1 border rounded w-full border-gray-300 disabled:text-black transition-all delay-75 duration-300 ease-in-out"
                                :class="{
                                  'appearance-none border-none': isRegistered,
                                  'bg-stone-100': !isRegistered && !data.school_level,
                                }"
                                :disabled="isRegistered || !data.school_level"
                              >
                                <option value="">選択</option>
                                <option v-for="g in gakumens[data.school_level]" :value="g">{{ g }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:items-center dividy-y md:divide-y-0 md:divide-x w-full md:w-1/2 md:justify-end">
                          <div class="flex-none w-full md:w-8 p-1 md:text-right">
                            組
                          </div>
                          <div class="grow">
                            <div class="p-1 bg-white w-full">
                              <select 
                                v-model="data.class_name" 
                                class="px-2 py-1 border rounded w-full border-gray-300 disabled:text-black transition-all delay-75 duration-300 ease-in-out"
                                :class="{
                                  'appearance-none border-none': isRegistered,
                                  'bg-stone-100': !isRegistered && !data.grade,
                                }"
                                :disabled="isRegistered || !data.grade"
                              >
                                <option value="">選択</option>
                                <option v-for="i in 4" :value="`${i}組`">{{ i }}組</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="!isRegistered" class="text-gray-500 text-xs p-1 bg-stone-100">
                    ※ 複数在学の場合は長子の学年・組を選択してください。
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col md:flex-row md:items-center bg-stone-100 divide-y md:divide-x md:divide-y-0">
              <div class="flex-none w-40 p-1 md:text-right">
                参加人数
              </div>
              <div class="grow bg-white md:bg-transparent">
                <div class="flex items-center gap-2 md:bg-stone-100">
                  <div class="flex-none w-fit p-1">
                    {{ person }}人
                  </div>
                  <div v-show="!isRegistered" class="grow">
                    <div 
                      class="flex flex-col leading-tight transition-all delay-75 duration-300 ease-in-out h-full"
                    >
                      <span class="text-xs text-gray-500">※ {{ person }}人分のQRコードが生成されます</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- qrcodes -->
          <div 
            v-show="isRegistered && qrcodes.length" 
            class="grid grid-cols-2 grid-flow-row md:grid-rows-none gap-4 mt-4 transition-all delay-75 duration-300 ease-in-out md:grid-cols-5"
          >
            <div 
              v-for="(qrcode, idx) in qrcodes" 
              class="flex flex-col shadow-sm rounded-sm bg-gray-100 overflow-hidden transition-all delay-75 duration-300 ease-in-out md:max-w-32"
              :class="{
                'md:col-start-3': qrcodes.length == 1,
                'md:col-start-2': idx == 0 && (qrcodes.length == 2 || qrcodes.length == 3),
              }"
            >
              <figure class="w-full transition-all delay-75 duration-300 ease-in-out">
                <img :src="qrcode.url" :alt="qrcode.name" :title="qrcode.name" class="w-full" loading="lazy" />
              </figure>
              <div class="w-full flex flex-col p-0 transition-all delay-75 duration-300 ease-in-out">
                <div class="font-semibold px-0.5 truncate text-xs text-center">{{ qrcode.name }}</div>
                <button 
                  class="bg-primary text-white font-semibold flex justify-center items-center px-2 py-1 w-full text-sm md:text-xs hover:opacity-80 transition-all delay-75 duration-300 ease-in-out"
                  @click.prevent="downloadQRCode(qrcode)"
                >ダウンロード</button>
              </div>
            </div>
          </div>
        </div>
        
        <div 
          class="flex"
          :class="{
            'justify-end': !isRegistered,
            'justify-start': isRegistered,
          }"
        >
          <hr class="w-full md:w-1/3 transition-all delay-75 duration-300 mt-4" />
        </div>
        <div 
          class="flex items-center gap-2 p-2 md:p-4"
          :class="{
            'justify-end': !isRegistered,
            'justify-start': isRegistered,
          }"
        >
          <div class="w-full md:w-fit">
            <button v-if="!isRegistered"
              class="px-2 py-1 bg-primary text-white rounded w-full md:w-fit md:min-w-28 hover:opacity-80 flex items-center gap-1 justify-center"
              @click.prevent="register"
            >
              登録
              <ArrowLeftIcon class="w-4 h-4 rotate-180" />
            </button>
            <button v-else
              class="px-2 py-1 border rounded w-full md:w-fit md:min-w-28 hover:opacity-80 flex items-center gap-1 justify-center"
              @click.prevent="reset"
            >
              <ArrowLeftIcon class="w-4 h-4" />
              登録をやり直す
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
