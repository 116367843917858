<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue'
import { useClubRequestStore } from '../../../js/store/club-request';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import DateTimeHelper from '../../../js/helper/DatetimeHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubRequestStore = useClubRequestStore()

const emit = defineEmits(['add', 'edit', 'filter'])

const calendar = ref(null)

const calendarOptions = computed(() => {
  return {
    customButtons: {
      filterEvent: {
        text: clubRequestStore.showFilter ? '閉じる' : 'フィルター',
        className: 'btn filter-event',
        click: () => emit('filter'),
      },
      addEvent: {
        text: '新規登録',
        className: 'btn add-event bg-primary text-white',
        click: () => emit('add'),
      },
    },
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    views: {
      dayGridMonth: {
        displayEventEnd: true,
      },
    },
    headerToolbar: {
      left: 'filterEvent addEvent prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    buttonText: {
      today: '今日',
      year: '年',
      month: '月',
      week: '週',
      day: '日',
      list: '予定リスト',
    },
    weekText: '週',
    allDayText: '終日',
    moreLinkText(n) {
      return '他 ' + n + ' 件';
    },
    firstDay: 1,
    noEventsText: '表示する予定はありません',
    locale: 'ja',
    events: clubRequestStore.items,
    eventMouseEnter: (info) => {
      // set pointer
      info.el.style.cursor = 'pointer'
      // show room name when hover event
      info.el.setAttribute('title', info.event.extendedProps.hoverText)
    },
    dayMaxEventRows: 5,
    // eventLimit: true,
    eventClick: async (info) => {
      if (info.event.extendedProps.other) {
        $root.push.warning('この予定は他の部屋で登録されています。')
        return
      }

      $root.overlay.loading = true
      await clubRequestStore.getItem(info.event.extendedProps.id ?? info.event.id)
      emit('edit')
      $root.overlay.loading = false
    },
    datesSet: async (info) => {
      $root.overlay.loading = true
      clubRequestStore.filter.date = {
        start: DateTimeHelper.format(info.start, 'YYYY-MM-DD') + ' 00:00:00',
        end: DateTimeHelper.format(info.end, 'YYYY-MM-DD') + ' 00:00:00',
      }

      await clubRequestStore.getItems()
      $root.overlay.loading = false
    },
  }
})

onMounted(() => {
  const ranger = calendar.value.calendar.currentData.dateProfile.renderRange
  clubRequestStore.filter.date = {
    start: DateTimeHelper.format(ranger.start, 'YYYY-MM-DD') + ' 00:00:00',
    end: DateTimeHelper.format(ranger.end, 'YYYY-MM-DD') + ' 00:00:00',
  }
})
</script>
<template>
  <FullCalendar 
    ref="calendar"
    class="w-full h-full" 
    :options="calendarOptions" 
  />
</template>
