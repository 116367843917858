<script setup>
import { getCurrentInstance, onMounted } from 'vue'
import { useConfigStore } from '../../../js/store/configuration'
import APPJSH from '../../../js/helper/ConstantHelper'
import ValidateHelper from '../../../js/helper/ValidateHelper'
import TextHelper from '../../../js/helper/TextHelper'

const configStore = useConfigStore()
const $root = getCurrentInstance()?.appContext.config.globalProperties

onMounted(async () => {
   $root.overlay.loading = true
   await configStore.getConfig(APPJSH.EVENT_TICKET)
   $root.overlay.loading = false
})

const save = async () => {
   // validate required fields
   if (
      ValidateHelper.isEmpty(configStore.emailConfig.from) || 
      !ValidateHelper.isEmail(configStore.emailConfig.from)
   ) {
      $root.push.error('メールアドレスを正しく入力してください')
      return
   }
   if (
      ValidateHelper.isEmpty(configStore.emailConfig.name) || 
      ValidateHelper.isEmpty(configStore.emailConfig.host) || 
      ValidateHelper.isEmpty(configStore.emailConfig.port) || 
      ValidateHelper.isEmpty(configStore.emailConfig.encryption)
   ) {
      $root.push.error('必須項目を入力してください')
      return
   }

   $root.overlay.loading = true
   await configStore.updateConfig(APPJSH.EVENT_TICKET)
   $root.overlay.loading = false

   $root.push.success('設定を保存しました')
}

const validate = async () => {
   // validate required fields
   if (
      ValidateHelper.isEmpty(configStore.emailConfig.from) || 
      !ValidateHelper.isEmail(configStore.emailConfig.from)
   ) {
      $root.push.error('メールアドレスを正しく入力してください')
      return
   }
   if (
      ValidateHelper.isEmpty(configStore.emailConfig.name) || 
      ValidateHelper.isEmpty(configStore.emailConfig.host) || 
      ValidateHelper.isEmpty(configStore.emailConfig.port) || 
      ValidateHelper.isEmpty(configStore.emailConfig.encryption)
   ) {
      $root.push.error('必須項目を入力してください')
      return
   }

   $root.overlay.loading = true
   const resposne = await configStore.validateConfig(APPJSH.EVENT_TICKET)
   if (resposne) {
      $root.push.success('設定を検証しました')
   } else {
      $root.push.error('設定を検証できませんでした')
   }

   $root.overlay.loading = false
}
</script>
<template>
   <div class="w-full bg-white rounded-md p-4 flex flex-col items-center gap-4">
      <div class="flex flex-col border border-t-2 border-t-secondary w-full lg:max-w-lg bg-graysp divide-y">
         <div class="px-2 py-px font-semibold">
            メール情報と設定
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  メールアドレス
                  <span class="text-xxs text-error">
                     ※必須
                  </span>
               </div>
            </div>
            <div class="grow">
               <div class="flex w-full bg-white p-2">
                  <input 
                     type="text" 
                     class="w-full px-2 py-0.5 rounded border max-w-lg"
                     v-model="configStore.emailConfig.from"
                  />
               </div>
            </div>
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  差出人名
                  <span class="text-xxs text-error">
                     ※必須
                  </span>
               </div>
            </div>
            <div class="grow">
               <div class="flex w-full bg-white p-2">
                  <input 
                     type="text" 
                     class="w-full px-2 py-0.5 rounded border max-w-lg"
                     v-model="configStore.emailConfig.name"
                  />
               </div>
            </div>
         </div>
      </div>
      <div class="flex flex-col border border-t-2 border-t-secondary w-full lg:max-w-lg bg-graysp divide-y">
         <div class="px-2 py-px font-semibold">
            メール情報と設定
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  ホスト名
                  <span class="text-xxs text-error">
                     ※必須
                  </span>
               </div>
            </div>
            <div class="grow">
               <div class="flex w-full bg-white p-2">
                  <input 
                     type="text" 
                     class="w-full px-2 py-0.5 rounded border max-w-lg"
                     v-model="configStore.emailConfig.host"
                  />
               </div>
            </div>
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  ユーザー名
               </div>
            </div>
            <div class="grow">
               <div class="flex w-full bg-white p-2">
                  <input 
                     type="text" 
                     class="w-full px-2 py-0.5 rounded border max-w-lg"
                     v-model="configStore.emailConfig.username"
                  />
               </div>
            </div>
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  パスワード
               </div>
            </div>
            <div class="grow">
               <div class="flex w-full bg-white p-2">
                  <input 
                     type="password" 
                     class="w-full px-2 py-0.5 rounded border max-w-lg"
                     placeholder="********"
                     v-model="configStore.emailConfig.password"
                  />
               </div>
            </div>
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  ポート番号
                  <span class="text-xxs text-error">
                     ※必須
                  </span>
               </div>
            </div>
            <div class="grow bg-white">
               <div class="flex bg-white p-2 w-32">
                  <input 
                     type="number" 
                     class="w-full px-2 py-0.5 rounded border max-w-lg appearance-none"
                     placeholder="465"
                     v-model="configStore.emailConfig.port"
                     step="1"
                     min="1"
                  />
               </div>
            </div>
         </div>
         <div class="flex items-center">
            <div class="flex-none w-1/3">
               <div class="flex items-center justify-between p-1">
                  暗号化の種類
                  <span class="text-xxs text-error">
                     ※必須
                  </span>
               </div>
            </div>
            <div class="grow bg-white">
               <div class="flex w-fit bg-white p-2">
                  <select 
                     class="w-full px-2 py-0.5 rounded border max-w-lg"
                     v-model="configStore.emailConfig.encryption"
                  >
                     <option v-for="item in APPJSH.EMAIL_CRYPTS" :value="item">
                        {{ TextHelper.toUpperCase(item) }}
                     </option>
                  </select>
               </div>
            </div>
         </div>
      </div>
      <div></div>
      <div class="flex items-center justify-center gap-6">
         <!-- validate button -->
         <button 
            type="button" 
            class="px-4 py-1 text-white bg-primary rounded font-semibold hover:opacity-80"
            @click.prevent="validate"
         >設定を検証</button>

         <!-- save button -->
         <button 
            type="button" 
            class="px-4 py-1 text-white bg-tertiary rounded font-semibold hover:opacity-80"
            @click.prevent="save"
         >設定を保存</button>
      </div>
   </div>
</template>
