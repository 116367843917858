<script setup>
import { getCurrentInstance } from 'vue'
import { useClubRequestStore } from '../../../js/store/club-request';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubRequestStore = useClubRequestStore()

const applyFilter = async () => {
  $root.overlay.loading = true
  await clubRequestStore.getItems()
  $root.overlay.loading = false
}

const clearFilterRoom = () => {
  clubRequestStore.filter.rooms = []
}

const clearFilterResource = () => {
  clubRequestStore.filter.resources = []
}
</script>
<template>
  <div class="flex flex-col w-full gap-2 h-auto max-h-full overflow-y-auto transition-all delay-75 duration-300 ease-in-out min-w-48">
    <div class="flex items-center">
      <!-- apply filter -->
      <button 
        class="bg-blue-500 text-white rounded px-2 py-1.5 w-full"
        @click.prevent="applyFilter"
      >適用</button>
    </div>
    <!-- Rooms -->
    <div v-if="clubRequestStore.rooms.length" class="flex flex-col">
      <div class="flex items-center justify-between">
        <h4 class="font-semibold">施設</h4>
        <!-- check all/clear toogle -->
        <div class="flex items-center gap-1 text-xxs">
          <button 
            class="text-blue-500"
            @click.prevent="clubRequestStore.filter.rooms = clubRequestStore.rooms.map(room => room.id)"
          >全選択</button>
          /
          <button 
            class="text-error" 
            @click.prevent="clearFilterRoom"
          >クリア</button>
        </div>
      </div>

      <label 
        v-for="room in clubRequestStore.rooms"
        class="flex items-start gap-1 text-gray-500"
        :title="room.name"
      >
        <div class="flex-none w-4">
          <input 
            type="checkbox" 
            :value="room.id" 
            class="w-4 h-4" 
            v-model="clubRequestStore.filter.rooms" 
          />
        </div>
        <div class="grow truncate">
          <span>{{ room.name }}</span>
        </div>
      </label>
    </div>

    <!-- Resource -->
    <div v-if="clubRequestStore.resources.length" class="flex flex-col">
      <div class="flex items-center justify-between">
        <h4 class="font-semibold">物品</h4>
        <!-- check all/clear toogle -->
        <div class="flex items-center gap-1 text-xxs">
          <button 
            class="text-blue-500"
            @click.prevent="clubRequestStore.filter.resources = clubRequestStore.resources.map(resource => resource.id)"
          >全選択</button>
          /
          <button 
            class="text-error"
            @click.prevent="clearFilterResource"
          >クリア</button>
        </div>
      </div>

      <label 
        v-for="resource in clubRequestStore.resources"
        class="flex items-start gap-1 text-gray-500"
        :title="resource.name"
      >
        <div class="flex-none w-4">
          <input 
            type="checkbox" 
            :value="resource.id" 
            class="w-4 h-4" 
            v-model="clubRequestStore.filter.resources"
          />
        </div>
        <div class="grow truncate">
          <span>{{ resource.name }}</span>
        </div>
      </label>
    </div>
  </div>
</template>
