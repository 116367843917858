import { defineStore } from 'pinia'

export const useRoom = defineStore('room', {
  state: () => ({
    item: {},
    items: [],
    expanded: true,
    search: '',
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/classrooms`);
      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    }
  }
})
